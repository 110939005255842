// actions.js
export const setMainMenu = (menu) => {
  return {
    type: "SET_MAIN_MENU",
    payload: menu,
  };
};

export const setIpAddress = (menu) => {
  return {
    type: "SET_IP_ADDRESS",
    payload: menu,
  };
};

export const countryStateCity = (menu) => {
  return {
    type: "SET_COUNTRY_STATE_CITY",
    payload: menu,
  };
};