import { Autocomplete, Box, Button, DialogContent, FormControl, InputLabel, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import { convertToDayjs, createLabelValue } from "../common/function/common";
import ConfirmationModal from "../components/confirmationModal";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import EditMemoDiamond from "../memo-view/edit-memo-diamond";
import DCustomDatePicker from "../components/DCustomDatePicker";

const AddEdit = React.memo(({ open, onClose, setMemoRows, type, withExcel, type_id, form_name }) => {
  const diamondIdRef = React.useRef(null);
  const masterFillAPI = usePostData(`/master_fill`);
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const labSendViewAPI = usePostData(`/lab_send_view`);
  const labSendSave = usePostData(`/lab_send_save`);
  const deleteAPI = usePostData(`/lab_send_delete`);
  const [columns, setColumns] = useState([]);
  const [selectData, setSelectData] = useState({
    fianancial_year_name: [],
    lab_name: [],
    lab_intake_site_name: [],
    lab_account_name: [],
  });
  const [visibleColumn, setVisibleColumn] = useState({});
  const [columnResponse, setColumnResponse] = useState(null);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedDates, setSelectedDates] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);
  const [openMemoForm, setOpenMemoForm] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });

  const fetchRows = async ({ name, diamond_id, auto_id }) => {
    setLoading(true);
    try {
      const responseData = await labSendViewAPI.mutateAsync({
        "trans_name": name,
        "diamond_id": diamond_id,
        "auto_id": auto_id
      });

      let responseDatas = responseData?.detail ?? responseData;
      const rowsData = responseDatas?.length !== 0 ? responseDatas?.map((row, index) => ({
        id: index + 1,
        ...row
      })) : [];

      if (name === "diamond_detail_by_id" && rowsData?.length && rowsData[0]?.success) {
        setRows((prevRows) => {
          const newRows = rowsData.filter(
            (newRow) => newRow?.success && !prevRows.some((prevRow) => prevRow.diamond_id === newRow.diamond_id)
          );
          return [...prevRows, ...newRows];
        });
      } else if (name === "lab_send_data_by_no") {
        setRows((prevRows) => {
          const newRows = rowsData.filter(
            (newRow) => !prevRows.some((prevRow) => prevRow.diamond_id === newRow.diamond_id)
          );
          return [...prevRows, ...newRows];
        });
      }
      setFormData((prevData) => ({ ...prevData, diamond_id: "" }));
      if (diamondIdRef.current) diamondIdRef.current.focus();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };


  const fetchColumn = async () => {
    try {
      const responseData = await viewColumnsFillAPI.mutateAsync({
        type_id: type_id,
        form_name: form_name,
        view_name: "lab_send_detail"
      });
      const alignmentMap = {
        3: 'right',
        2: 'center',
        1: 'left'
      };
      const columns = responseData?.map((col) => ({
        accessorKey: col.column_name,
        header: col.column_header,
        filterVariant: 'autocomplete',
        size: col?.column_width,
        columnType: col?.column_type,
        textAlign: alignmentMap[col?.column_align],
        filterFn: 'includesString',
        is_visible: col?.is_visible,
        is_visible_form: col?.is_visible_form,
        is_editable: col?.is_editable
      }));
      const actionsColumn = {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100, // Adjust size if needed
        textAlign: 'center',
        columnType: 'action', // Custom type
        is_visible: true,
        left: 0
      };
      columns.splice(0, 0, actionsColumn);
      setColumnResponse(columns);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchData = async (mastType, mastName, key) => {
    try {
      const responseData = await masterFillAPI.mutateAsync({
        "mast_type": mastType,
        "mast_name": mastName
      });

      return { [key]: responseData };
    } catch (error) {
      console.error('Error:', error);
      return { [key]: [] };
    }
  };
  const fetchAllData = async () => {
    try {
      const [fiananceYear, labName, labAccountName, memoReasonName] = await Promise.all([
        fetchData("select", "fianance_year", "fianancial_year_name"),
        fetchData("select", "lab", "lab_name"),
        fetchData("select", "lab_account", "lab_account_name"),
        fetchData("select", "lab_intake_site", "lab_intake_site_name")
      ]);

      setSelectData({
        ...fiananceYear, ...labName, ...labAccountName, ...memoReasonName
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const saveMemo = ({ values, table }) => {
    labSendSave.mutate({
      ...formData,
      diamond_data_list: rows.map((row) => ({ ...row })),
      fianancial_year_id: formData?.fianancial_year_name?.value,
      lab_intake_site_id: formData?.lab_intake_site_name?.value,
      lab_id: formData?.lab_name?.value,
      lab_account_id: formData?.lab_account_name?.value,
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          const parseData = JSON?.parse(responseData?.request_body_data)
          if (formData?.auto_id) {
            setMemoRows((prevData) =>
              prevData.map((item) =>
                item.auto_id === formData?.auto_id
                  ? {
                    ...parseData,
                    ...formData,
                    fianancial_year_name: parseData?.fianancial_year_name?.label,
                    lab_intake_site_name: parseData?.lab_intake_site_name?.label,
                    lab_name: parseData?.lab_name?.label,
                    lab_account_name: parseData?.lab_account_name?.label,
                  }
                  : item
              )
            );
          } else {
            // Push new data to memo rows
            setMemoRows((prevData) => [
              ...prevData,
              {
                ...parseData,
                ...formData,
                lab_send_no: responseData?.lab_send_no,
                auto_id: responseData?.auto_id,
                fianancial_year_name: parseData?.fianancial_year_name?.label,
                lab_intake_site_name: parseData?.lab_intake_site_name?.label,
                lab_name: parseData?.lab_name?.label,
                lab_account_name: parseData?.lab_account_name?.label,
              }
            ]);
          }
          setToastMess(responseData?.message)
          handleClose()
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
        setError(error);
      }
    });
  }

  const handleDelete = async (data) => {
    deleteAPI.mutate({
      auto_id: open?.auto_id,
      diamond_id_list: data?.diamond_id
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setRows((prevData) => prevData.filter((item) => item.auto_id !== open?.auto_id));
          setToastMess(responseData?.message)
          setOpenDeletemodal({ flag: false, data: null })
          setError("")
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  useEffect(() => {
    if (open?.srno || open?.length || type) {
      fetchColumn();
    }
  }, [open?.srno, open?.length, type]);

  useEffect(() => {
    const fetchAddressColumn = async () => {
      try {
        const responseData = await viewColumnsFillAPI.mutateAsync({
          "type_id": type_id,
          "form_name": form_name,
          "view_name": "lab_send_summary"
        });

        if (responseData) {
          setColumns(responseData.sort((a, b) => a.form_display_ord - b.form_display_ord));
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAddressColumn();
    fetchAllData();
  }, []);

  useEffect(() => {
    setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
      if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const handleClose = () => {
    onClose(null);
    setError('')
    setFormData({})
  };

  const handleChange = (newValue, columnId, columnType) => {
    if (columnType === "select") {
      setFormData({ ...formData, [columnId]: newValue });
    } else if (columnType === "switch") {
      setFormData({ ...formData, [columnId]: newValue.target.checked });
    } else {
      setFormData({ ...formData, [columnId]: newValue.target.value });
    }
  };

  useEffect(() => {
    if (open?.srno) {
      fetchRows({ name: "lab_send_data_by_no", auto_id: open?.auto_id })
      setFormData({
        ...open,
        ...(open?.fianancial_year_id && { fianancial_year_name: createLabelValue(open?.fianancial_year_name, open?.fianancial_year_id) }),
        ...(open?.lab_intake_site_id && { lab_intake_site_name: createLabelValue(open?.lab_intake_site_name, open?.lab_intake_site_id) }),
        ...(open?.lab_id && { lab_name: createLabelValue(open?.lab_name, open?.lab_id) }),
        ...(open?.lab_account_id && { lab_account_name: createLabelValue(open?.lab_account_name, open?.lab_account_id) }),
      })
      setSelectedDates({ ...selectedDates, trn_date: convertToDayjs(open?.trn_date) });
    }
  }, [open?.srno])

  const handleFocusOut = (e, columnKey, columnType) => {
    if (columnKey === "diamond_id" && e?.target?.value) {
      fetchRows({ name: "diamond_detail_by_id", diamond_id: formData?.diamond_id })
    }
  }

  return (
    <React.Fragment>
      <DModal
        open={Boolean(open)}
        backDrop
        onClose={handleClose}
        DialogTitle={"Lab Entry"}
        DialogContent={
          <>
            <DialogContent className="d_row" sx={{ padding: "20px 24px 5px 24px !important" }}>
              {
                columns &&
                columns?.filter(_ => _?.is_visible_form)?.map((item, index) => {
                  const columnType = item?.column_type;
                  const required = item?.is_required;
                  const columnKey = item?.column_name;
                  const cssClassName = item?.css_class_name;
                  const headerName = item?.column_header;
                  const disabled = item?.is_editable;
                  const isFirstField = index === 1;

                  if (columnType === "text") {
                    return (
                      <div className={cssClassName}>
                        <DTextField
                          key={index}
                          label={headerName}
                          required={required}
                          autoFocus={isFirstField}
                          value={formData[columnKey] || ''}
                          onChange={(e) => handleChange(e, columnKey, columnType)}
                          onBlur={(e) => handleFocusOut(e, columnKey, columnType)}
                          variant="outlined"
                          fullWidth
                          size="small"
                          disabled={!disabled}
                          inputRef={columnKey === "diamond_id" ? diamondIdRef : null}
                        />
                      </div>
                    );
                  }
                  if (columnType === 'select') {
                    let options = [];
                    options = selectData[columnKey];
                    return (
                      <div className={cssClassName}>
                        <FormControl fullWidth key={columnKey}>
                          <Autocomplete
                            value={formData[columnKey] || null}
                            onChange={(event, newValue) => handleChange(newValue, columnKey, 'select')}
                            options={options || []}
                            disabled={!disabled}
                            autoFocus={isFirstField}
                            getOptionLabel={(option) => option?.label ?? ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required={required}
                                label={headerName}
                                autoFocus={isFirstField}
                                fullWidth
                              />
                            )}
                            size="small"
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                          />

                        </FormControl>
                      </div>
                    );
                  }
                  if (columnType === 'date') {
                    const handleChange = (newValue) => {
                      setSelectedDates((prevDates) => ({
                        ...prevDates,
                        [columnKey]: newValue, // Store the Dayjs object directly
                      }));

                      const formattedDate = newValue ? newValue.format('DD-MM-YYYY') : '';

                      // Update formData with formatted date
                      setFormData((prevData) => ({ ...prevData, [columnKey]: formattedDate }));
                    };

                    return (
                      <div className={`${cssClassName} member-datepicker`}>
                        <DCustomDatePicker
                          headerName={headerName}
                          required={required}
                          selectedDate={selectedDates[columnKey]}
                          onChange={handleChange}
                          columnKey={columnKey}
                          disabled={!disabled}
                        />
                      </div>
                    );
                  }
                })
              }
              {error && <em style={{ color: "red" }}>{error}</em>}
            </DialogContent>
            <Box>
              <div style={{ height: "100%", width: "100%" }}>
                {(columnResponse && visibleColumn !== null) &&
                  <VirtualizedTableData
                    height={"440px"} rowSelection={type}
                    data={rows} visibleColumn={visibleColumn}
                    handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data })}
                    hideEdit
                    loading={!loading && !viewColumnsFillAPI?.isPending}
                    columnResponse={columnResponse}
                    rowCount={0}
                  />}
              </div>
            </Box>
          </>
        }
        DialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" autoFocus onClick={saveMemo}>Save</Button>
          </>
        }
      />
      <EditMemoDiamond open={openMemoForm} onClose={setOpenMemoForm} setMemoRows={setRows} />
      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Memo?"}
        title={
          <>Are you sure you want to delete this entry?</>
        }
        action={() => handleDelete(openDeletemodal?.data)}
      />
      <ToastBar open={toastMess} onClose={setToastMess} />
    </React.Fragment>
  );
});

export default AddEdit;
