import { Box, Button } from "@mui/material";
import React from "react"
import DModal from "./DModal";
import Magnifier from "react-magnifier";

const ImgVideoModal = ({ data, onClose }) => {
    return (
        <React.Fragment>
            <DModal
                open={data?.url}
                onClose={onClose}
                backDrop
                background="white"
                modalWidth={data?.type === "image" ? "800px" : "900px"}
                DialogTitle={data?.type === "image" ? "Diamond Image" : "Diamond Video"}
                DialogContent={
                    <React.Fragment>
                        {data?.type === "image" ?
                            <Box margin="0 auto">
                                <Magnifier src={data?.url} width={500} height={500} zoomFactor={1} />
                            </Box>
                            :
                            <Box margin="0 auto" width={600} height={600} marginY={2}>
                                <iframe style={{border: "none"}} src={data?.url} width={600} height={600}/>
                            </Box>
                        }
                    </React.Fragment>
                }
                DialogActions={
                    <Button
                        onClick={onClose}
                        variant="contained"
                        color="secondary"
                        sx={{ background: "linear-gradient(90deg, #2476ca 0%, #174ea6 100%)" }}
                    >
                        Close
                    </Button>
                }
            />
        </React.Fragment>
    )
};

export default ImgVideoModal;
