import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import imageCompression from 'browser-image-compression';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../common/function/getCroppedImg';

const AvatarUpload = ({ open, onClose, onImageCropped, initialImage }) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };

  const handleCrop = async () => {
    if (croppedAreaPixels && image) {
      const croppedImg = await getCroppedImg(image, croppedAreaPixels);
      onImageCropped(croppedImg);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '600px',
        },
        '& .MuiModal-backdrop': {
          backgroundColor: "rgba(0, 0, 0, 0.4)"
        },
      }}
    >
      <DialogTitle sx={{ padding: "15px", display: "flex", alignItems: "center", justifyContent: "space-between" }} variant="h6">Upload Profile Image
        <input
          accept="image/*"
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="file-upload">
          <Button variant="contained" component="span">
            {image ? "Change Image" : "Upload Image"}
          </Button>
        </label>
      </DialogTitle>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {image && (
          <Box sx={{ position: 'relative', height: '400px', width: '400px', mt: 2 }}>
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </Box>
        )}
        {image && (
          <Box sx={{ textAlign: "end", width: '100%', p: 2 }}>
            <Button sx={{ mr: "10px" }} variant="text" onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={handleCrop}>Save</Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default AvatarUpload;
