export const COLOR = {
    primary: "#1f76ce",
    gray: "#a5a5a5",
    lightGray: "#f0f0f0",
    white: "#FFFFFF",
    black: "#000000",
}


export const fetchHeaders = {
    method: "POST",
    headers: { 'Content-Type': 'application/json' }
  }

export const APIURL = "https://erpapi.dholakia.co"
// export const APIURL = "http://192.168.213.130:2024"