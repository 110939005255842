import { Autocomplete, Button, debounce, FormControl, FormControlLabel, Switch, TextField, useMediaQuery } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import { getPartyDataFromUrl, getValues } from "../common/function/common";
import DTextField from "../components/DTextField";
import CountryCodeWithPhoneInput from "../components/MobileCountrySelect";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import ToastBar from "../components/ToastBar";

const PartyMember = lazy(() => import('./party-member'));
const PartyMemberAddress = lazy(() => import('./party-member-address'));
const AdatBrokerReference = lazy(() => import('./adat-broker-reference'));
const Document = lazy(() => import('./document'));
const BillingFinance = lazy(() => import('./billing-finance'));

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: 'calc(100% - 25%)', overflowY: "auto" }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PartyDetails = React.memo((props) => {
  const partyData = getPartyDataFromUrl("data");
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const masterViewAPI = usePostData(`/master_view`);
  const masterFillAPI = usePostData(`/master_fill`);
  const saveAPI = usePostData(`/party_save`);
  const [value, setValue] = React.useState(0);
  const [tabs, setTabs] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [allFields, setAllFields] = React.useState([]);
  const [error, setError] = useState('');
  const [reFetchMember, setReFetchMember] = useState(false);
  const [reFetchData, setReFetchData] = useState(false);
  const [toastMess, setToastMess] = useState('');
  const [updateData, setUpdateData] = useState(false);
  const [multiSelectData, setMultiSelectData] = useState({
    party_type_name: [],
    group_party_name: [],
    sale_person_name: [],
    classification_name: [],
    business_type_name: [],
    party_rating_name: [],
    isd_code1_name: [],
    member_type: [],
  });

  const createFunction = async () => {
    const requestData = {
      party_data: {
        ...formData,
        party_code: formData?.party_code,
        party_type_id: getValues(formData?.party_type_name),
        group_party_code: getValues(formData?.group_party_name),
        sale_person_id: getValues(formData?.sale_person_name),
        classification_id: getValues(formData?.classification_name),
        business_type_id: formData?.business_type_name?.value,
        isd_code_id: formData?.contact_no?.value,
        contact_no: formData?.contact_no?.number,
        isd_code1_id: formData?.contact_no1?.value,
        isd_code2_id: formData?.contact_no2?.value,
        contact_no1: formData?.contact_no1?.number,
        contact_no2: formData?.contact_no2?.number,
        party_rating_id: formData?.party_rating_name?.value,
        member_type_id: formData?.member_type_name?.value
      },
    };

    saveAPI.mutate(requestData, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setError("")
          setReFetchMember(true)
          setToastMess(responseData?.message)
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const fetchData = async (mastType, mastName, key) => {
    try {
      const responseData = await masterFillAPI.mutateAsync({
        "mast_type": mastType,
        "mast_name": mastName
      });

      return { [key]: responseData };
    } catch (error) {
      console.error('Error:', error);
      return { [key]: [] };
    }
  };

  const fetchAllData = async () => {
    try {
      const [salePerson, memberType, groupParty, classification, subPartyType, businessType, partyRating, isdCode] = await Promise.all([
        fetchData("select", "sale_person", "sale_person_name"),
        fetchData("select", "member_type", "member_type_name"),
        fetchData("select", "group_party", "group_party_name"),
        fetchData("select", "party_classification", "classification_name"),
        fetchData("select", "party_type", "party_type_name"),
        fetchData("select", "business_type", "business_type_name"),
        fetchData("select", "party_rating", "party_rating_name"),
        fetchData("select", "isd_code", "isd_code1_name")
      ]);

      setMultiSelectData({
        ...multiSelectData,
        ...memberType,
        ...salePerson,
        ...groupParty,
        ...classification,
        ...subPartyType,
        ...businessType,
        ...partyRating,
        ...isdCode
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTabs = async () => {
    try {
      const responseData = await masterFillAPI.mutateAsync({
        mast_name: "party_tab"
      });

      if (responseData) {
        setTabs(responseData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchPartyColumn = async () => {
    viewColumnsFillAPI.mutate({
      type_id: partyData?.type_id,
      form_name: partyData?.form_name,
      view_name: "party_detail"
    }, {
      onSuccess: (responseData) => {
        if (responseData) {
          const columnData = responseData?.filter(_ => _?.is_visible_form).map((col) => ({
            accessorKey: col.column_name,
            header: col.column_header,
            size: col.column_width,
            filterVariant: 'autocomplete',
            flex: 1,
            filterFn: 'includesString',
            is_visible: col?.is_visible,
            is_required: col?.is_required,
            css_class_name: col?.css_class_name,
            column_type: col?.column_type,
            muiTableBodyCellProps: ({ cell }) => ({
              children:
                ["switch"]?.includes(col.column_type) ? (
                  <label className="label toggle">
                    <input type="checkbox" checked={cell.getValue()} className="toggle_input" />
                    <div className="toggle-control"></div>
                  </label>
                ) : (
                  cell.getValue()
                ),
            }),
          }));
          setAllFields(columnData);
          setUpdateData(false)
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  }

  const checkLength = (value) => {
    // Check if the value is a non-null string before splitting
    if (typeof value === 'string') {
      const valuesArray = value.split(',');
      return valuesArray.length;
    }
    return 0;
  };

  const fetchRowData = async () => {
    masterViewAPI.mutate({
      party_code: partyData?.party_code,
      view_name: "party_detail_by_code"
    }, {
      onSuccess: (responseData) => {
        if (responseData && Array.isArray(responseData) && responseData.length > 0) {
          const editingRow = responseData[0];

          const prepareFieldData = (idField, nameField) => {
            if (checkLength(editingRow[idField]) === 1) {
              return [{ label: editingRow[nameField], value: Number(editingRow[idField]) }];
            }
            return (typeof editingRow[idField] === 'string' ? editingRow[idField].split(",") : [])
              .map((id, index) => ({
                value: Number(id),
                label: typeof editingRow[nameField] === 'string' ? editingRow[nameField].split(",")[index] : ""
              })) ?? [];
          };

          const preparedData = {
            ...editingRow,
            party_code: editingRow?.party_code,
            business_type_name: { label: editingRow?.business_type_name, value: editingRow?.business_type_id },
            contact_no: { label: editingRow?.isd_code_name, value: editingRow?.isd_code_id, number: editingRow?.contact_no },
            contact_no1: { label: editingRow?.isd_code1_name, value: editingRow?.isd_code1_id, number: editingRow?.contact_no1 },
            contact_no2: { label: editingRow?.isd_code2_name, value: editingRow?.isd_code2_id, number: editingRow?.contact_no2 },
            party_rating_name: { label: editingRow?.party_rating_name, value: editingRow?.party_rating_id },
            member_type_name: { label: editingRow?.member_type_name, value: editingRow?.member_type_id },
            party_type_name: prepareFieldData('party_type_id', 'party_type_name'),
            group_party_name: prepareFieldData('group_party_code', 'group_party_name'),
            sale_person_name: prepareFieldData('sale_person_id', 'sale_person_name'),
            classification_name: prepareFieldData('classification_id', 'classification_name'),
            is_active: editingRow?.is_active
          };

          setFormData(preparedData);
        }

      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };


  useEffect(() => {
    if (updateData || reFetchData) fetchAllData();
  }, [updateData, reFetchData]);

  useEffect(() => {
    if (updateData || reFetchData) {
      if (!reFetchData) {
        fetchTabs();
        fetchPartyColumn();
      }
      if (partyData?.party_code || reFetchData) fetchRowData()
    }
  }, [updateData, reFetchData])

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    fetchTabs();
    fetchPartyColumn();
    if (partyData?.party_code) fetchRowData()
  }, [])

  const handleTabChange = debounce((event, newValue) => {
    setValue(newValue);
    // Update the URL with the new tab value
    const url = new URL(window.location);
    url.searchParams.set('tab', newValue);
    window.history.pushState({}, '', url);
  }, 100);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get('tab');

    if (tabParam) {
      const tabIndex = Number(tabParam);
      if (!isNaN(tabIndex) && tabIndex < tabs.length) {
        setValue(tabIndex);
      }
    }
  }, [tabs]); // Make sure to run this when tabs are set  

  const handleChange = (event, columnId, columnType) => {
    if (columnType === "select") {
      setFormData({ ...formData, [columnId]: event.target.value });
    } else if (columnType === "switch") {
      if (columnId === "is_party_name_as_per_member_name") {
        if (event.target.checked) {
          setFormData({ ...formData, [columnId]: event.target.checked, party_name: `${formData.first_name} ${formData.middle_name} ${formData.last_name}` });
        } else {
          setFormData({ ...formData, [columnId]: event.target.checked, party_name: `` });
        }
      } else setFormData({ ...formData, [columnId]: event.target.checked });
    } else if (columnType === "contact") {
      setFormData({
        ...formData,
        [columnId]: {
          ...formData[columnId],
          number: event.target.value
        }
      });
    } else if (columnId === "party_type_name") {
      if ((event.target.value?.length === 1 && event.target.value?.find(_ => _?.value === 3))) {
        setFormData({ ...formData, [columnId]: event.target.value, party_name: "" });
      } else {
        setFormData({ ...formData, [columnId]: event.target.value, is_party_name_as_per_member_name: false, party_name: "" });
      }
    } else {
      setFormData({ ...formData, [columnId]: event.target.value });
    }
  };

  return (
    <React.Fragment>
      <div style={{ overflow: "auto" }}>
        <Box sx={{ position: useMediaQuery('(max-width:881px)') ? "relative" : "sticky", top: 0, paddingTop: "10px", background: "white", zIndex: 11, borderBottom: 1, borderColor: 'divider' }}>
          <div className="d_row" style={{ margin: "20px" }}>
            {formData && allFields?.filter(_ => _?.accessorKey !== "is_party_name_as_per_member_name").map((component, index) => {
              const columnKey = component?.accessorKey;
              const columnType = component?.column_type;
              const cssClassName = component?.css_class_name;
              const required = component?.is_required;
              const headerName = component?.header;

              // Ensure formData has properties to render
              if (columnKey === 'isd_code_name' || columnKey === 'isd_code2_name' || columnKey === 'isd_code1_name') {
                return null;
              }

              // Check for contact type
              if (columnType === 'contact') {
                return (
                  <div className={cssClassName} key={columnKey}>
                    <CountryCodeWithPhoneInput
                      names={multiSelectData?.isd_code1_name}
                      selectedCode={formData[columnKey] || { value: '', label: '' }}
                      phoneNumber={formData[columnKey]?.number || ''}
                      handleChange={handleChange}
                      columnKey={columnKey}
                      required={required}
                      columnType={columnType}
                      headerName={headerName}
                    />
                  </div>
                );
              }

              // For select type
              if (columnType === 'select') {
                const options = columnKey === 'isd_code2_name' ? multiSelectData.isd_code1_name : multiSelectData[columnKey];
                const selectedOption = options?.find(option => option?.value === formData[columnKey]?.value) || null;

                return (
                  <div className={cssClassName} key={columnKey}>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={selectedOption}
                        onChange={(event, newValue) => handleChange({ target: { value: newValue } }, columnKey, columnType)}
                        options={options}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required={required}
                            label={headerName}
                            fullWidth
                            size="small"
                            InputLabelProps={{
                              sx: {
                                fontSize: '14px',
                                lineHeight: '22px',
                              },
                            }}
                          />
                        )}
                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                      />
                    </FormControl>
                  </div>
                );
              }

              if (columnType === 'multi-select') {
                let options = [];
                if (columnKey === 'party_type_name') {
                  options = multiSelectData.party_type_name;
                } else {
                  options = multiSelectData[columnKey];
                }

                // Ensure formData[columnKey] is an array
                const selectedValues = Array.isArray(formData[columnKey]) ? formData[columnKey] : [];

                const selectedOptions = options.filter(option => selectedValues.some(item => item?.value === option?.value)) || [];

                const handleSelectionChange = (newValue) => {
                  const selectedValues = newValue.map(option => option);
                  handleChange({ target: { value: selectedValues } }, columnKey, columnType);
                };

                return (
                  <div className={cssClassName} key={columnKey}>
                    <FormControl fullWidth>
                      <MultipleSelectCheckmarks
                        options={options}
                        required={required}
                        value={selectedOptions}
                        handleChange={handleSelectionChange}
                        headerName={headerName}
                      />
                    </FormControl>
                  </div>
                );
              }


              // Text input
              if (columnType === "text") {
                return (
                  <div className={cssClassName} key={columnKey}>
                    <DTextField
                      required={required}
                      label={headerName}
                      value={formData[columnKey] || ''}
                      onChange={(e) => handleChange(e, columnKey, columnType)}
                      variant="outlined"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        sx: {
                          fontSize: '14px',
                          lineHeight: '22px',
                        },
                      }}
                      disabled={(columnKey === 'party_name' && formData?.party_type_name?.length === 1 && formData?.party_type_name?.find(_ => _?.value === 3)) || columnKey === 'party_code'}
                    />
                  </div>
                );
              }

              // Switch input
              if (columnType === "switch") {
                return (
                  <div className={headerName === "Active" ? "d_col-2" : "d_col-4"} key={columnKey}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData[columnKey] || false}
                          onChange={(e) => handleChange(e, columnKey, columnType)}
                        />
                      }
                      label={headerName}
                    />
                  </div>
                );
              }

              return null; // Return null if no conditions are met
            })}
            {formData?.party_type_name?.length > 1 && formData?.party_type_name?.some(item => item.value === 3) && allFields?.filter(_ => _?.accessorKey === "is_party_name_as_per_member_name").map((component, index) => {
              const columnKey = component?.accessorKey;
              const columnType = component?.column_type;
              const headerName = component?.header;
              return (
                <div className={headerName === "Active" ? "d_col-2" : "d_col-4"} key={columnKey}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formData[columnKey] || false}
                        onChange={(e) => handleChange(e, columnKey, columnType)}
                      />
                    }
                    label={headerName}
                  />
                </div>
              );
            })}
            <Button variant="contained" sx={{ position: "absolute", right: "25px", bottom: "50px" }} onClick={() => createFunction()}>Save</Button>

            {error && <em style={{ color: "red" }}>{error}</em>}
          </div>
          <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleTabChange} sx={{ minHeight: '32px', borderTop: 1, borderColor: 'divider' }}>
            {tabs?.map((item, index) => {
              return <Tab label={item?.name} {...a11yProps(index)} sx={{ minHeight: '40px', padding: '0 16px' }} />
            })}
          </Tabs>
        </Box>
        <Box>
          <CustomTabPanel value={value} index={0}>
            <PartyMember
              updateData={updateData}
              setUpdateData={setUpdateData}
              reFetchMember={reFetchMember}
              setReFetchMember={setReFetchMember}
              reFetchData={reFetchData}
              setReFetchData={setReFetchData}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <PartyMemberAddress  {...props} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AdatBrokerReference {...props} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Document {...props} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <BillingFinance {...props} />
          </CustomTabPanel>
        </Box>
        <ToastBar open={toastMess} onClose={setToastMess} />
      </div>
    </React.Fragment>
  );
});

export default PartyDetails;