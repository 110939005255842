import { Close, Delete, Edit } from "@mui/icons-material";
import { Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, Switch, TextField, Tooltip } from "@mui/material";
import { MRT_EditActionButtons } from "material-react-table";
import React, { lazy, useEffect, useState } from "react"
import { APIURL, fetchHeaders } from "../common/constant";
import AddAddressModal from "../branch-details/add-address-modal";
import { useSelector } from "react-redux";
import ConfirmationModal from "./confirmationModal";
import { usePostData } from "../common/function/api";
import DTextField from "./DTextField";
import DModal from "./DModal";
const MRTable = lazy(() => import("./MRTable"));

const BranchCompanyModal = React.memo(({ table, row, internalEditComponents, formData, setFormData, handleChange, companyData, error, type, modalWidth, addressModal }) => {

    const countryStateCityData = useSelector((state) => state.countryStateCityData);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterViewAPI = usePostData(`/master_view`);
    const saveAPI = usePostData(`/branch_address_save`);
    const deleteAPI = usePostData(`/branch_address_delete`);
    const [rows, setRows] = useState([]);
    const [column, setColumn] = useState([]);
    const [formDatas, setFormDatas] = useState({});
    const [editingRow, setEditingRow] = useState(null);
    const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
    const [addressError, setAddressError] = useState('');
    const [toastMess, setToastMess] = useState(null);
    const deleteConfirmation = async (id) => {
        deleteAPI.mutate({ "branch_address_id": id }, {
            onSuccess: (responseData) => {
                if (responseData?.success) {
                    setRows((prevData) => prevData.filter((item) => item.branch_address_id !== id));
                    setOpenDeletemodal({ flag: false, data: null });
                    setToastMess(responseData)
                }
            },
            onError: (error) => {
                console.error('Error:', error);
            },
        });
    };
    const fetchAddressData = async () => {
        masterViewAPI.mutate({
            "view_name": "branch_address",
            "branch_id": row?.original?.branch_id
        }, {
            onSuccess: (responseData) => {
                if (responseData) {
                    const rowsData = responseData.map((row, index) => ({
                        id: index + 1,
                        ...row
                    }));
                    setRows(rowsData)
                }
            },
            onError: (error) => {
                console.error('Error:', error);
            },
        });
    }
    const fetchAddressColumn = async () => {
        viewColumnsFillAPI.mutate({
            "type_id": 0,
            "form_name": "branch_address"
        }, {
            onSuccess: (responseData) => {
                const columnData = responseData?.filter(_ => _?.is_visible).map((col) => {
                    return ({
                        accessorKey: col.column_name,
                        header: col.column_header,
                        size: col.column_width,
                        filterVariant: 'autocomplete',
                        flex: 1,
                        filterFn: 'includesString',
                        is_visible: col?.is_visible,
                        is_required: col?.is_required,
                        cssClassName: col?.css_class_name,
                        column_type: col?.column_type,
                        muiTableBodyCellProps: ({ cell }) => ({
                            // Custom rendering logic for the is_active and is_default columns
                            children:
                                (["is_default", "is_active"]?.includes(col.column_name)) ? (
                                    <label className="label toggle">
                                        <input type="checkbox" checked={cell.getValue()} className="toggle_input" />
                                        <div className="toggle-control"></div>
                                    </label>
                                ) : (
                                    cell.getValue()
                                ),
                        }),
                    })
                });
                setColumn(columnData);
            },
            onError: (error) => {
                console.error('Error:', error);
            },
        });
    }
    useEffect(() => {
        fetchAddressData()
        fetchAddressColumn()
    }, [])

    const addAddressModal = (props, type) => (
        <AddAddressModal
            {...props}
            type={type}
            formData={formData}
            addressFormData={formDatas}
            setAddressFormData={setFormDatas}
            addressError={addressError}
            addAddress
        />
    );

    // Function to handle creating a new row
    const createAddress = async ({ row, values, table }) => {
        const requestData = {
            branch_address_data: {
                branch_address: formDatas?.branch_address,
                ...(formDatas?.branch_address_id && { branch_address_id: formDatas?.branch_address_id }),
                ...(formData?.branch_id && { branch_id: formData?.branch_id }),
                is_active: formDatas?.is_active,
                is_default: formDatas?.is_default ?? false,
                city_id: formDatas?.city_name,
                country_id: formDatas?.country_name,
                state_id: formDatas?.state_name,
                gst_no: formDatas?.gst_no,
                zip_code: formDatas?.zip_code,
                pan_no: formDatas?.pan_no,
            }
        };

        try {
            const responseData = await saveAPI.mutateAsync(requestData);
            if (responseData?.success) {
                const parsedData = JSON?.parse(responseData?.request_body_data)?.branch_address_data;
                if (row?.original?.branch_address_id) {
                    setRows((prevData) =>
                        prevData.map((item) =>
                            item.branch_address_id === formDatas?.branch_address_id
                                ? {
                                    ...parsedData,
                                    ...(responseData?.branch_address_id && { branch_address_id: responseData?.branch_address_id }),
                                    ...(parsedData?.branch_id && { branch_id: parsedData?.branch_id }),
                                    is_active: parsedData?.is_active,
                                    is_default: parsedData?.is_default,
                                    city_name: countryStateCityData?.city?.find(_ => _?.value === parsedData?.city_id)?.label,
                                    country_name: countryStateCityData?.country?.find(_ => _?.value === parsedData?.country_id)?.label,
                                    state_name: countryStateCityData?.state?.find(_ => _?.value === parsedData?.state_id)?.label,
                                }
                                : item
                        )
                    );
                    setEditingRow(null);
                    table.setEditingRow(null);
                } else {
                    setRows((prevData) => [...prevData, {
                        ...parsedData,
                        ...(responseData?.branch_address_id && { branch_address_id: responseData?.branch_address_id }),
                        ...(parsedData?.branch_id && { branch_id: parsedData?.branch_id }),
                        is_active: parsedData?.is_active,
                        is_default: parsedData?.is_default,
                        city_name: countryStateCityData?.city?.find(_ => _?.value === parsedData?.city_id)?.label,
                        country_name: countryStateCityData?.country?.find(_ => _?.value === parsedData?.country_id)?.label,
                        state_name: countryStateCityData?.state?.find(_ => _?.value === parsedData?.state_id)?.label,
                    }]);
                    table.setCreatingRow(null);
                }
                setFormDatas({});
                setToastMess(responseData);
            } else {
                setAddressError(responseData?.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        if (editingRow) {
            const preparedData = {
                ...editingRow,
                is_active: editingRow?.is_active,
                is_default: editingRow?.is_default,
                country_name: editingRow?.country_id,
                city_name: editingRow?.city_id,
                state_name: editingRow?.state_id,
            }
            setFormDatas(preparedData);
        }
    }, [editingRow]);
    const handleClose = () => {
        setEditingRow(null);
        setFormDatas({});
        setFormData({});
        setOpenDeletemodal({ flag: false, data: null }); // Reset delete modal state if needed
        table.setCreatingRow(null);
        table.setEditingRow(null);
        // Additional cleanup can be added here if needed
    };
    const isCreate = type === 'create';
    return (
        <React.Fragment>
            <DModal
                open={true}
                modalWidth={modalWidth}
                onClose={handleClose}
                DialogTitle={isCreate ? 'Add New' : row?.original?.branch_name ?? "Edit"}
                DialogContent={<>
                    <DialogContent className="d_row" sx={{ padding: "20px 24px 5px 24px !important" }}>
                        {internalEditComponents?.filter(_ => _?.props?.cell?.column?.columnDef?.is_visible_form).sort((a, b) => a?.props?.cell?.column?.columnDef?.form_display_ord - b?.props?.cell?.column?.columnDef?.form_display_ord).map((component, index) => {
                            const columnKey = component?.props?.cell?.column?.id;
                            const columnType = component?.props?.cell?.column?.columnDef?.column_type;
                            const required = component?.props?.cell?.column?.columnDef?.is_required;
                            const cssClassName = component?.props?.cell?.column?.columnDef?.css_class_name;
                            const headerName = component.props.cell.column.columnDef.header;
                            const isFirstField = index === 1;
                            if (columnKey === 'company_id' || columnKey === 'branch_id') {
                                return null;
                            }

                            if (columnType === 'select') {
                                return (
                                    <div className={cssClassName}>
                                        <FormControl fullWidth key={columnKey}>
                                            <Autocomplete
                                                value={companyData.find((option) => option.value === formData[columnKey]) || null}
                                                onChange={(event, newValue) => handleChange({ target: { value: newValue?.value } }, columnKey, columnType)}
                                                options={companyData || []}
                                                autoFocus={isFirstField}
                                                getOptionLabel={(option) => { return option.label ? option.label : "Unknown Label"; }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required={required}
                                                        label={headerName}
                                                        fullWidth
                                                        autoFocus={isFirstField}
                                                    />
                                                )}
                                                size="small"
                                                isOptionEqualToValue={(option, value) => option.value === value}
                                            />
                                        </FormControl>
                                    </div>
                                );
                            }

                            if (columnType === "text") {
                                return (
                                    <div className={cssClassName}>
                                        <DTextField
                                            key={index}
                                            label={headerName}
                                            required={required}
                                            value={formData[columnKey] || ''}
                                            onChange={(e) => handleChange(e, columnKey, columnType)}
                                            variant="outlined"
                                            autoFocus={isFirstField}
                                            fullWidth
                                            size="small"
                                            disabled={!isCreate && (columnKey === 'branch_code' || columnKey === 'company_code')}
                                        />
                                    </div>
                                );
                            }

                            if (columnType === "switch") {
                                return (
                                    <div className={cssClassName} >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formData[columnKey] || false}
                                                    autoFocus={isFirstField}
                                                    onChange={(e) => handleChange(e, columnKey, columnType)}
                                                />
                                            }
                                            label={headerName}
                                        />
                                    </div>
                                );
                            }

                            return React.cloneElement(component, { key: index });
                        })}
                        {error && <em style={{ color: "red" }}>{error}</em>}
                    </DialogContent>
                    {addressModal && !isCreate && <div style={{ padding: "0 24px 16px" }}>
                        <MRTable
                            className="table-header"
                            columns={column || []}
                            rows={rows || []}
                            btnText="Address"
                            maxHeight="400px"
                            formData={formDatas}
                            setFormData={setFormDatas}
                            editingRow={editingRow}
                            setEditingRow={setEditingRow}
                            createFunction={createAddress}
                            editFunction={createAddress}
                            openDeletemodal={openDeletemodal}
                            setOpenDeletemodal={setOpenDeletemodal}
                            deleteConfirmation={deleteConfirmation}
                            toastMess={toastMess}
                            setToastMess={setToastMess}
                            error={addressError}
                            setError={setAddressError}
                            renderCreateRowDialogContent={(e) => addAddressModal(e, "create")}
                            renderEditRowDialogContent={(e) => addAddressModal(e, "edit")}
                            renderRowActions={({ row, table }) => (
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Tooltip title="Edit">
                                        <IconButton sx={{ padding: 0 }} onClick={() => {
                                            setEditingRow(row?.original);
                                            table.setEditingRow(row);
                                        }}>
                                            <Edit sx={{ height: '16px', width: '16px' }} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip sx={{ padding: 0 }} title="Delete">
                                        <IconButton color="error" onClick={() => setOpenDeletemodal({ flag: true, data: row?.original })}>
                                            <Delete sx={{ height: '16px', width: '16px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                        />
                    </div>
                    }
                </>}
                DialogActions={<MRT_EditActionButtons variant="text" table={table} row={row} />}
            />

            <ConfirmationModal
                open={openDeletemodal?.flag}
                onClose={() => setOpenDeletemodal({ flag: false, data: null })}
                header={"Delete Branch Address?"}
                title={
                    <>Are you sure you want to delete <strong>"{openDeletemodal?.data?.branch_address}"</strong> branch address?</>
                }
                action={() => deleteConfirmation(openDeletemodal?.data?.branch_address_id)}
            />
        </React.Fragment>
    );
});

export default BranchCompanyModal;
