import { TextField, InputAdornment, IconButton } from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const DTextField = (props) => {
  const { onChange, isUppercase = true, type = "text", ...rest } = props; // Destructure type with a default value of "text"
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const handleChange = (event) => {
    let value = event.target.value;
    if (isUppercase) {
      value = value.toUpperCase(); // Transform to uppercase only if isUppercase is true
    }
    // Call the original onChange handler with the modified value
    onChange({ ...event, target: { ...event.target, value } });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev); // Toggle the password visibility
  };

  return (
    <TextField
      {...rest}
      autoComplete="off"
      type={type === "password" && showPassword ? "text" : type || "text"} // Use provided type or fall back to "text"
      onChange={handleChange}
      InputProps={{
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
              {showPassword ? <VisibilityOff sx={{ height: '16px', width: '16px' }} /> : <Visibility sx={{ height: '16px', width: '16px' }} />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DTextField;
