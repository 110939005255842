import { Button, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";
import ToastBar from "../components/ToastBar";

const EditMemoDiamond = React.memo(({ open, onClose, setMemoRows }) => {
  const [formData, setFormData] = useState({
    carat: '',
    rap: '',
    memo_disc: '',
    memo_rate: '',
    memo_amount: ''
  });
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);

  const handleClose = () => {
    onClose(null);
    setError('');
    setFormData({
      carat: '',
      rap: '',
      memo_disc: '',
      memo_rate: '',
      memo_amount: ''
    });
  };

  const handleChange = (e, name) => {
    const value = parseFloat(e?.target?.value) || 0;
    let updatedData = { ...formData, [name]: value };

    // 1st formula: When memo_disc changes, update memo_rate
    if (name === "memo_disc") {
      updatedData.memo_rate = (updatedData.rap + (updatedData.rap * value / 100)).toFixed(2);
      updatedData.memo_amount = (updatedData.memo_rate * updatedData.carat).toFixed(2); // Update memo_amount
    }

    // 2nd formula: When memo_rate changes, update memo_amount
    else if (name === "memo_rate") {
      updatedData.memo_amount = (value * updatedData.carat).toFixed(2);
      updatedData.memo_disc = ((value / updatedData.rap * 100) - 100).toFixed(2); // Update memo_disc
    }

    // 3rd formula: When memo_amount changes, update memo_rate
    else if (name === "memo_amount") {
      updatedData.memo_rate = (value / updatedData.carat).toFixed(2);
      updatedData.memo_disc = ((updatedData.memo_rate / updatedData.rap * 100) - 100).toFixed(2); // Update memo_disc
    }

    // 4th formula: When carat changes, update memo_amount (depends on memo_rate)
    else if (name === "carat") {
      updatedData.memo_amount = (updatedData.memo_rate * value).toFixed(2);
    }

    // Set updated data with formatted values
    setFormData(updatedData);
  };

  const saveMemo = () => {
    setMemoRows(prevData =>
      prevData.map(item =>
        item.diamond_id === formData.diamond_id ? { ...formData } : item
      )
    );
    handleClose();
  };

  useEffect(() => {
    if (open?.memo_no) {
      setFormData({ ...open });
    }
  }, [open?.memo_no]);

  const isCreateMode = !open?.memo_no;

  const styles = {
    dialog: {
      '& .MuiInputBase-root': {
        background: "white",
        boxShadow: "1px 2px 6px #e9e9e9"
      },
      '& .MuiDialog-paper': {
        width: '520px',
        maxWidth: '100%',
        borderRadius: "11px",
        background: "#f9f9f9"
      }
    },
    dialogContent: {
      padding: "20px 24px 5px 24px !important"
    }
  };

  return (
    <>
      <DModal
        open={Boolean(open)}
        onClose={handleClose}
        modalWidth="520px"
        backDrop
        DialogTitle={`Diamond - ${open?.diamond_id}`}
        DialogContent={
          <DialogContent className="d_row" sx={styles.dialogContent}>
            {["carat", "rap", "memo_disc", "memo_rate", "memo_amount"].map(field => (
              <div key={field} className="d_col-6">
                <DTextField
                  label={field.replace("_", " ").replace(/\b\w/g, char => char.toUpperCase())}
                  name={field}
                  value={formData[field]}
                  onChange={(e) => handleChange(e, field)}
                  variant="outlined"
                  disabled={field === "carat" || field === "rap"} // Disable input for carat and rap
                  fullWidth
                  size="small"
                />
              </div>
            ))}
            {error && <em style={{ color: "red" }}>{error}</em>}
          </DialogContent>
        }
        DialogActions={
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" autoFocus onClick={saveMemo}>Save</Button>
          </>
        }
      />
      <ToastBar open={toastMess} onClose={setToastMess} />
    </>
  );
});

export default EditMemoDiamond;

/*
Discount change === rap + (rap * disc / 100) === memo rate 
memo rate change ===  memo rate * carat === amount
amount change === amount / carat ===  rate
rate change === (rate / rap * 100) - 100 === disc
*/