import { Autocomplete, Button, DialogContent, FormControl, FormControlLabel, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";

const ScheduleForm = React.memo(({ open, onClose, type_id, view_name, form_name, selectedParty, setRows, setToastMess }) => {
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterFillAPI = usePostData(`/master_fill`);
    const partyStockScheduleSaveAPI = usePostData(`/party_stock_schedule_save`);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState('');
    const [fieldsResposne, setFieldsResposne] = useState(null);
    const [selectData, setSelectData] = useState({
        protocol_name: [],
        service_type: [],
        party_name: [],
    });

    const handleClose = () => {
        onClose(null);
        setError('');
        setFormData({});
    };

    const handleChange = (eventOrValue, name, type) => {
        if (type === "text") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: eventOrValue.target.value,
            }));
        } else if (type === "select") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: eventOrValue,
            }));
            setError("")
        } else setFormData({ ...formData, [name]: eventOrValue.target.checked });
    };

    const saveSchedule = async () => {
        try {
            const responseData = await partyStockScheduleSaveAPI.mutateAsync({
                ...formData,
                ...(formData.id && { id: formData.id }),
                party_code: formData.party_name?.value,
                protocol_name: formData.protocol_name?.value,
                service_type: formData.service_type?.value,
            });
            if (responseData?.success) {
                setToastMess(responseData?.message)
                const parseData = JSON?.parse(responseData?.request_body_data)
                const updatedRow = {
                    ...parseData,
                    ...(responseData?.id && { id: responseData?.id }),
                    party_name: parseData.party_name?.label || parseData.party_name,
                    protocol_name: parseData.protocol_name,
                    service_type: parseData.service_type,
                };
                // Update or add the row in `setRows`
                if (formData?.hasOwnProperty("id")) {
                    setRows((prevData) =>
                        prevData.map((item) =>
                            item.id === parseData.id ? { ...item, ...updatedRow } : item
                        )
                    );
                } else {
                    setRows((prevData) => [...prevData, updatedRow]);
                }
                handleClose();
            } else setError(responseData?.message)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_type": mastType,
                "mast_name": mastName
            });

            return { [key]: responseData };
        } catch (error) {
            console.error('Error:', error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [serviceType, protocolName, partyName] = await Promise.all([
                fetchData("select", "stock_remote_service", "service_type"),
                fetchData("select", "remote_protocol", "protocol_name"),
                fetchData("select", "party", "party_name"),
            ]);

            setSelectData({
                ...serviceType, ...protocolName, ...partyName
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchFields = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id,
                form_name,
                view_name
            });
            setFieldsResposne(responseData);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchFields();
        fetchAllData();
    }, []);

    useEffect(() => {
        if (open?.data) {
            let values = open?.data;
            setFormData({
                ...values,
                service_type: { label: values?.service_type, value: values?.service_type },
                protocol_name: { label: values?.protocol_name, value: values?.protocol_name },
                party_name: { label: values?.party_name, value: values?.party_code },
            });
        }
    }, [open?.data, selectData]);

    return (
        <>
            <DModal
                open={Boolean(open?.flag)}
                onClose={handleClose}
                backDrop
                DialogTitle={`Diamond`}
                DialogContent={
                    <DialogContent className="d_row" sx={{ padding: "20px 24px 5px 24px !important" }}>
                        {
                            fieldsResposne &&
                            fieldsResposne?.filter(_ => _?.is_visible_form)?.map((item, index) => {
                                const columnType = item?.column_type;
                                const required = item?.is_required;
                                const columnKey = item?.column_name;
                                const cssClassName = item?.css_class_name;
                                const headerName = item?.column_header;
                                const disabled = item?.is_editable;
                                const isFirstField = index === 0;

                                if (columnType === "text") {
                                    return (
                                        <div className={cssClassName}>
                                            <DTextField
                                                key={index}
                                                label={headerName}
                                                required={required}
                                                autoFocus={isFirstField}
                                                value={formData[columnKey] || ''}
                                                type={["port_number", "service_schedule_time"].includes(columnKey) ? "number" : "text"}
                                                onChange={(e) => handleChange(e, columnKey, columnType)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                disabled={!disabled}
                                            />
                                        </div>
                                    );
                                }
                                if (columnType === 'select') {
                                    return (
                                        <div className={cssClassName} key={index}>
                                            <FormControl fullWidth key={columnKey}>
                                                <Autocomplete
                                                    value={formData[columnKey] || null}
                                                    autoFocus={isFirstField}
                                                    onChange={(event, newValue) => handleChange(newValue, columnKey, 'select')}
                                                    options={selectData[columnKey] || []}
                                                    disabled={!disabled}
                                                    getOptionLabel={(option) => option?.label ?? ""}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            required={required}
                                                            autoFocus={isFirstField}
                                                            label={headerName}
                                                            fullWidth
                                                        />
                                                    )}
                                                    size="small"
                                                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                                    getOptionDisabled={(option) => !option?.label}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option?.value || option?.label}>
                                                            {option?.label}
                                                        </li>
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                    )
                                }
                                if (columnType === "switch") {
                                    return (
                                        <div className={cssClassName} key={columnKey}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={formData[columnKey] || false}
                                                        onChange={(e) => handleChange(e, columnKey, columnType)}
                                                    />
                                                }
                                                label={headerName}
                                            />
                                        </div>
                                    );
                                }
                            })
                        }
                        {error && <em style={{ color: "red" }}>{error}</em>}
                    </DialogContent>
                }
                DialogActions={
                    <>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" autoFocus onClick={saveSchedule}>Save</Button>
                    </>
                }
            />
        </>
    );
});

export default ScheduleForm;