import { Add, Clear, Search } from "@mui/icons-material";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import ConfirmationModal from "../components/confirmationModal";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import AddEdit from "./add-edit";
import dayjs from "dayjs";
import DCustomDatePicker from "../components/DCustomDatePicker";

const LabSend = React.memo(({ form_name, type_id }) => {
  const boxRef = useRef(null);
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const labSendViewAPI = usePostData(`/lab_send_view`);
  const deleteAPI = usePostData(`/lab_send_delete`);
  const [boxHeight, setBoxHeight] = useState(0);
  const [visibleColumn, setVisibleColumn] = useState({});
  const [columnResponse, setColumnResponse] = useState(null);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({
    from_date: dayjs().startOf('month').format('DD-MM-YYYY'),
    to_date: dayjs().endOf('month').format('DD-MM-YYYY'),
  });
  const [selectedDates, setSelectedDates] = useState({});
  const [loading, setLoading] = useState(false);
  const [toastMess, setToastMess] = useState(null);
  const [openMemoForm, setOpenMemoForm] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });

  useEffect(() => {
    // Get the height of the div after it is rendered
    if (boxRef.current) {
      const height = boxRef.current.clientHeight;
      setBoxHeight(height + 85);
    }
  }, []);


  const fetchRows = async (resetFields = false) => {
    setLoading(true);

    if (resetFields) {
      // Clear all fields in formData
      setFormData({
        memo_type_name: null,
        party_name: null,
        broker_name: null,
      });
    }

    try {
      const responseData = await labSendViewAPI.mutateAsync({
        "trans_name": "lab_send_summary",
        "auto_id": 0,
        "from_date": formData?.from_date,
        "to_date": formData?.to_date,
      });

      const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({
        id: index + 1,
        ...row
      })) : [];

      setRows(rowsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  const fetchColumn = async () => {
    try {
      const responseData = await viewColumnsFillAPI.mutateAsync({
        type_id: type_id,
        form_name: form_name,
        view_name: "lab_send_summary"
      });
      const alignmentMap = {
        3: 'right',
        2: 'center',
        1: 'left'
      };
      const columns = responseData?.map((col) => ({
        accessorKey: col.column_name,
        header: col.column_header,
        filterVariant: 'autocomplete',
        size: col?.column_width,
        columnType: col?.column_type,
        textAlign: alignmentMap[col?.column_align],
        filterFn: 'includesString',
        is_visible: col?.is_visible,
        is_visible_form: col?.is_visible_form
      }));
      const actionsColumn = {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100, // Adjust size if needed
        textAlign: 'center',
        columnType: 'action', // Custom type
        is_visible: true
      };
      columns.splice(0, 0, actionsColumn);
      setColumnResponse(columns);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchColumn();
    fetchRows();
  }, []);

  useEffect(() => {
    setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
      if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const handleDateChange = (newValue, name) => {
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: newValue,
    }));
    const formattedDate = newValue ? newValue.format('DD-MM-YYYY') : '';
    setFormData((prevData) => ({ ...prevData, [name]: formattedDate }));
  };

  const handleDelete = async (id) => {
    deleteAPI.mutate({
      auto_id: id?.auto_id,
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setRows((prevData) => prevData.filter((item) => item.auto_id !== id?.auto_id));
          setToastMess(responseData?.message)
          setOpenDeletemodal({ flag: false, data: null })
        } else {
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const handleEdit = (data) => {
    if (data === "data") {
      setOpenMemoForm(data)
    } else {
      const fetchRows = async () => {
        setLoading(true);
        try {
          const responseData = await labSendViewAPI.mutateAsync({
            "trans_name": "lab_send_data_by_no",
            "auto_id": data.auto_id
          });
          const responseDatas = responseData?.summary[0] ?? responseData;
          setOpenMemoForm(responseDatas);
        } catch (error) {
          console.error('Error:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchRows();
    }
  };

  return (
    <React.Fragment>
      <Box ref={boxRef} sx={{ display: "flex", justifyContent: "space-between", padding: "15px 15px 0 15px" }}>
        <div className="d_row" style={{ width: "100%" }}>
          <div className="d_col-2">
            <div className={`member-datepicker`}>
              <DCustomDatePicker
                headerName={"From Date"}
                required={false}
                selectedDate={selectedDates?.from_date ?? dayjs().startOf('month')}
                onChange={(e) => handleDateChange(e, "from_date")}
                columnKey={"from_date"}
              />
            </div>
          </div>
          <div className="d_col-2">
            <div className={`member-datepicker`}>
              <DCustomDatePicker
                headerName={"To Date"}
                required={false}
                selectedDate={selectedDates?.to_date ?? dayjs().endOf('month')}
                onChange={(e) => handleDateChange(e, "to_date")}
                columnKey={"to_date"}
              />
            </div>
          </div>
        </div>
        <Box>
          <Box display="flex" gap={1} marginLeft={2} marginBottom="10px">
            <Button variant="contained" onClick={() => fetchRows()} startIcon={<Search />} sx={{ ...(loading && { color: "white !important" }) }}>{loading ? "Finding..." : "Find"}</Button>
            <Button variant="contained" onClick={() => fetchRows(true)} startIcon={<Clear />}>Clear</Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <div style={{ height: `calc(100vh - ${boxHeight}px)`, width: "100%" }}>
          {(columnResponse && visibleColumn !== null) &&
            <VirtualizedTableData
              style={{ margin: 0 }}
              className="left-0"
              height={`calc(100vh - ${boxHeight}px)`}
              data={rows} visibleColumn={visibleColumn}
              loading={!loading && !viewColumnsFillAPI?.isPending}
              columnResponse={columnResponse}
              handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data })}
              handleEdit={(data) => handleEdit(data)}
              rowCount={0}
              uploadExcel={<Box><Button onClick={() => handleEdit("data")} variant="contained" startIcon={<Add />}>Add</Button></Box>}
            />}
        </div>
      </Box>
      {openMemoForm && <AddEdit form_name={form_name} type_id={type_id} open={openMemoForm} onClose={setOpenMemoForm} setMemoRows={setRows} withExcel />}
      <ToastBar open={toastMess} onClose={setToastMess} />
      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Entry?"}
        title={
          <>Are you sure you want to delete this entry?</>
        }
        action={() => handleDelete(openDeletemodal?.data)}
      />
    </React.Fragment>
  );
});

export default LabSend;
