import React, { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { Base64 } from "js-base64";
import { Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePostData } from "../common/function/api";
import { encryptText } from "../common/function/common";

const Login = React.memo(() => {
    const navigate = useNavigate();
    const loginAPI = usePostData(`/login`);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [fieldValue, setFieldValue] = useState({
        email: "",
        password: "",
        remember: false,
    });
    const [showPassword, setShowPassword] = useState(false);

    const handle = {
        forgotPass: () => {
            navigate("/forgot-password");
        },
        register: () => {
            navigate("/register");
        },
        change: (value, name) => {
            setFieldValue({ ...fieldValue, [name]: value });
        },
        submit: async (e) => {
            e.preventDefault();
            setLoading(true);

            loginAPI.mutate({
                "user_name": fieldValue.email,
                "user_pass": encryptText(fieldValue.password)
            }, {
                onSuccess: (responseData) => {
                    setLoading(false);
                    if (responseData?.success) {
                        let res = { ...responseData, email: fieldValue.email, password: fieldValue.password };
                        localStorage.setItem('userAuth', Base64.encode(JSON.stringify(res)));
                        navigate("/dashboard");
                    } else {
                        setError(responseData?.message);
                    }
                },
                onError: (error) => {
                    console.error('Error:', error); // Log the error
                },
            });
        },
        handleClickShowPassword: () => {
            setShowPassword((show) => !show);
        },
    };

    return (
        <div className='d_login'>
            <Container component="main" maxWidth="xs" className='d_login'>
                <div className="SignInContainer" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <img src="/images/dholakia-logo.png" height="90px" />
                    <Typography component="h1" variant="h5">Sign in</Typography>
                    <Typography component="h6" variant="h6">Welcome back to your account</Typography>

                    <form style={{ width: '100%', marginTop: "8px" }} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            value={fieldValue?.email}
                            onChange={(e) => handle.change(e.target.value, "email")}
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            sx={{ marginBottom: "20px" }}
                        />
                        <FormControl sx={{ width: '100%' }} required variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => handle.change(e.target.value, "password")}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handle.handleClickShowPassword}
                                            onMouseDown={(event) => event.preventDefault()}
                                            onMouseUp={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        {error && <em style={{ color: "red" }}>{error}</em>}<br />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ margin: "24px 0 16px" }}
                            sx={{ ...(loading && { color: "white !important" }) }}
                            onClick={(e) => handle.submit(e)}
                            disabled={loading}  // Disable button when loading
                            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}  // Add loader icon
                        >
                            {loading ? "Signing In..." : "Sign In"}
                        </Button>
                    </form>
                </div>
            </Container>
        </div>
    );
});

export default Login;
