import { Search } from "@mui/icons-material";
import { Box, Button, FormControl } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import { getLocalStorage, getValues } from "../common/function/common";
import ConfirmationModal from "../components/confirmationModal";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import SaleView from "../inventory/sale-view";

const Sale = React.memo(({ open, onClose }) => {
  const boxRef = useRef(null);
  const masterFillAPI = usePostData(`/master_fill`);
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const saleViewAPI = usePostData(`/sale_view`);
  const deleteAPI = usePostData(`/sale_delete`);
  const userData = getLocalStorage("userAuth");
  const [selectData, setSelectData] = useState({
    memo_type_name: [],
    party_name: [],
    broker_name: [],
    bill_type_name: [],
    bill_party_name: [],
    sale_person_name: [],
  });
  const [boxHeight, setBoxHeight] = useState(0);
  const [visibleColumn, setVisibleColumn] = useState({});
  const [columnResponse, setColumnResponse] = useState(null);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);
  const [openMemoForm, setOpenMemoForm] = useState(null);
  const [clearFilters, setClearFilters] = useState(false);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
  useEffect(() => {
    // Get the height of the div after it is rendered
    if (boxRef.current) {
      const height = boxRef.current.clientHeight;
      setBoxHeight(height + 100);
    }
  }, []);
  const fetchData = async (mastType, mastName, key) => {
    try {
      const responseData = await masterFillAPI.mutateAsync({
        "mast_type": mastType,
        "mast_name": mastName
      });

      return { [key]: responseData };
    } catch (error) {
      console.error('Error:', error);
      return { [key]: [] };
    }
  };

  const fetchAllData = async () => {
    try {
      const [partyName, brokerName, memoReasonName, billType, billParty, salePerson] = await Promise.all([
        fetchData("select", "party", "party_name"),
        fetchData("select", "broker", "broker_name"),
        fetchData("select", "bill_type", "bill_type_name"),
        fetchData("select", "party", "bill_party_name"),
        fetchData("select", "sale_person", "sale_person_name"),
      ]);

      setSelectData({
        ...partyName, ...brokerName, ...memoReasonName, ...billType, ...billParty, ...salePerson
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRows = async (resetFields = false) => {
    setLoading(true);

    if (resetFields) {
      // Clear all fields in formData
      setFormData({
        memo_type_name: null,
        party_name: null,
        broker_name: null,
      });
    }

    try {
      const responseData = await saleViewAPI.mutateAsync({
        "trans_name": "sale_summary",
        "auto_id": 0,
        ...(resetFields ? {} : {
          ...(formData?.bill_type_name && { bill_type_id: getValues(formData?.bill_type_name) }),
          ...(formData?.party_name && { party_code: getValues(formData?.party_name) }),
          ...(formData?.bill_party_name && { bill_party_code: getValues(formData?.bill_party_name) }),
          ...(formData?.sale_person_name && { sale_person_id: getValues(formData?.sale_person_name) }),
          ...(formData?.broker_name && { broker_code: getValues(formData?.broker_name) }),
        }),
      });

      const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({
        id: index + 1,
        ...row
      })) : [];

      setRows(rowsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  const fetchColumn = async () => {
    try {
      const responseData = await viewColumnsFillAPI.mutateAsync({
        type_id: 0,
        form_name: "sale",
        view_name: "sale_summary"
      });
      const alignmentMap = {
        3: 'right',
        2: 'center',
        1: 'left'
      };
      const columns = responseData?.map((col) => ({
        accessorKey: col.column_name,
        header: col.column_header,
        filterVariant: 'autocomplete',
        size: col?.column_width,
        columnType: col?.column_type,
        textAlign: alignmentMap[col?.column_align],
        filterFn: 'includesString',
        is_visible: col?.is_visible,
        is_visible_form: col?.is_visible_form
      }));
      const actionsColumn = {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100, // Adjust size if needed
        textAlign: 'center',
        columnType: 'action', // Custom type
        is_visible: true
      };
      columns.splice(0, 0, actionsColumn);
      setColumnResponse(columns);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchColumn();
    fetchAllData();
  }, []);

  useEffect(() => {
    setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
      if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const handleChange = (event, name) => {
    setFormData({ ...formData, [name]: event.target.value })
  };

  const handleDelete = async (id) => {
    deleteAPI.mutate({
      auto_id: id,
      user_id: userData?.user_id
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setRows((prevData) => prevData.filter((item) => item.auto_id !== id));
          setToastMess(responseData?.message)
          setOpenDeletemodal({ flag: false, data: null })
          setError("")
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const handleEdit = (data) => {
    setOpenMemoForm(data)
  }

  const handle = {
    selectedRowsChange: (diamondIds) => {
    }
  }

  const clearFilter = () => {
    setClearFilters(true); // Trigger clearing in child components

    // Reset clearFilters after filters are cleared
    setTimeout(() => {
      setClearFilters(false);
      setRows([]);
      setFormData({
        memo_type_name: null,
        party_name: null,
        broker_name: null,
      });
    }, 0); // Reset after clearing to avoid re-render loops
  }

  const sumRow = {};
  const rowsToSum = rows;
  const numberColumns = ['trn_no', 'carat', 'pcs', 'rap_amount', 'rate_amount', 'adv_amount', 'aadv_amount', 'less1_amount', 'less2_amount', 'sale_amount', 'broker_amount', 'adat_amount', 'disc', 'rate', 'sale_disc', 'sale_rate']
  numberColumns && numberColumns.forEach(column => {
    sumRow[column] = rowsToSum.reduce((acc, row) => acc + Number(row[column] || 0), 0).toFixed(2);
  });

  const totalRateAmount = rowsToSum.reduce((acc, row) => acc + Number(row['rate_amount'] || 0), 0);
  const totalRapAmount = rowsToSum.reduce((acc, row) => acc + Number(row['rap_amount'] || 0), 0);
  const totalCarat = rowsToSum.reduce((acc, row) => acc + Number(row['carat'] || 0), 0);
  const totalSaleAmount = rowsToSum.reduce((acc, row) => acc + Number(row['sale_amount'] || 0), 0);

  const disc = ((totalRateAmount / totalRapAmount) * 100) - 100;
  const rate = totalRateAmount / totalCarat;
  const sale_disc = ((totalSaleAmount / totalRapAmount) * 100) - 100;
  const sale_rate = totalSaleAmount / totalCarat;

  sumRow['diamond_id'] = rows?.length;
  sumRow['disc'] = disc.toFixed(2);
  sumRow['rate'] = rate.toFixed(2);
  sumRow['sale_disc'] = sale_disc.toFixed(2);
  sumRow['sale_rate'] = sale_rate.toFixed(2);

  return (
    <React.Fragment>
      <Box ref={boxRef} sx={{ display: "flex", justifyContent: "space-between", padding: "15px 15px 0 15px" }}>
        <div className="d_row" style={{ width: "100%", gap: "10px" }}>
          {[
            { name: "bill_type_name", label: "Bill Type", value: formData?.bill_type_name, options: selectData?.bill_type_name },
            { name: "party_name", label: "Party", value: formData?.party_name, options: selectData?.party_name },
            { name: "bill_party_name", label: "Bill Party", value: formData?.bill_party_name, options: selectData?.bill_party_name },
            { name: "sale_person_name", label: "Sale Person", value: formData?.sale_person_name, options: selectData?.sale_person_name },
            { name: "broker_name", label: "Broker", value: formData?.broker_name, options: selectData?.broker_name },
          ].map((item, index) => (
            <div style={{ width: "15%" }} key={index}>
              <CustomAutocomplete
                label={item.label}
                value={item.value}
                name={item.name}
                onChange={handleChange}
                options={item.options}
              />
            </div>
          ))}
        </div>
        <Box>
          <Box display="flex" gap={1} marginLeft={2} marginBottom="10px">
            <Button variant="contained" onClick={() => fetchRows()} startIcon={<Search />} sx={{ ...(loading && { color: "white !important" }) }}>{loading ? "Finding..." : "Find"}</Button>
            <Button variant="contained" onClick={() => clearFilter()}>Clear</Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <div style={{ height: `calc(100vh - ${boxHeight}px)`, width: "100%" }}>
          {(columnResponse && visibleColumn !== null) &&
            <VirtualizedTableData
              style={{ margin: 0 }}
              height={`calc(100vh - ${boxHeight}px)`}
              data={rows} visibleColumn={visibleColumn}
              loading={!loading && !viewColumnsFillAPI?.isPending}
              columnResponse={columnResponse}
              handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data?.auto_id })}
              handleEdit={(data) => handleEdit(data)} rowSelection
              clearSelection={clearFilters}
              onSelectedRowsChange={handle?.selectedRowsChange} memoSumRow={sumRow}
              numberColumns={numberColumns}
              totalCountColumn={'trn_no'}
              selectedId="auto_id"
            />}
        </div>
      </Box>
      <SaleView open={openMemoForm} onClose={setOpenMemoForm} setMemoRows={setRows} />
      <ToastBar open={toastMess} onClose={setToastMess} />
      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Sale?"}
        title={
          <>Are you sure you want to delete this sale?</>
        }
        action={() => handleDelete(openDeletemodal?.data)}
      />
    </React.Fragment>
  );
});
const CustomAutocomplete = ({ name, label, value, onChange, options }) => {
  const handleSelectionChange = (newValue, name) => {
    const selectedValues = newValue.map(option => option);
    onChange({ target: { value: selectedValues } }, name);
  };
  return (
    <FormControl fullWidth>
      <MultipleSelectCheckmarks
        options={options || []}
        value={value || []}
        handleChange={(e) => handleSelectionChange(e, name)}
        headerName={label}
      />
    </FormControl>
  );
};


export default Sale;


/* Filter Seq. : bill type, party, bill party, sale person, broker*/