import { GridView } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import { getValues } from "../common/function/common";
import VirtualizedTableData from "../components/VirtualizedTableData";

const SpecialCut = () => {
    const boxRef = useRef(null);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const memoViewAPI = usePostData(`/transaction_view`);
    const [boxHeight, setBoxHeight] = useState(0);
    const [visibleColumn, setVisibleColumn] = useState({});
    const [columnResponse, setColumnResponse] = useState(null);
    const [rows, setRows] = useState([]);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isGridView, setIsGridView] = useState(false);

    useEffect(() => {
        if (boxRef.current) {
            const height = boxRef.current.clientHeight;
            setBoxHeight(height + 85);
        }
    }, []);

    const fetchRows = async (resetFields = false) => {
        setLoading(true);
        if (resetFields) {
            setFormData({
                memo_type_name: null,
                party_name: null,
                broker_name: null,
            });
        }
        try {
            const responseData = await memoViewAPI.mutateAsync({
                trans_name: "memo_summary",
                memo_no: 0,
                ...(resetFields
                    ? {}
                    : {
                        ...(formData?.memo_type_name && {
                            memo_type_id: getValues(formData?.memo_type_name),
                        }),
                        ...(formData?.party_name && {
                            party_code: getValues(formData?.party_name),
                        }),
                        ...(formData?.broker_name && {
                            broker_code: getValues(formData?.broker_name),
                        }),
                    }),
            });

            const rowsData =
                responseData?.length !== 0
                    ? responseData.map((row, index) => ({
                        id: index + 1,
                        image_url: "/images/11.jpg",
                        ...row,
                    }))
                    : [];

            setRows(rowsData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
        }
    };

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: 0,
                form_name: "memo",
                view_name: "memo_summary",
            });
            const alignmentMap = {
                3: "right",
                2: "center",
                1: "left",
            };
            const columns = responseData?.map((col) => ({
                accessorKey: col.column_name,
                header: col.column_header,
                filterVariant: "autocomplete",
                size: col?.column_width,
                columnType: col?.column_type,
                textAlign: alignmentMap[col?.column_align],
                filterFn: "includesString",
                is_visible: col?.is_visible,
                is_visible_form: col?.is_visible_form,
            }));
            setColumnResponse(columns);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchColumn();
        fetchRows();
    }, []);

    useEffect(() => {
        setVisibleColumn(
            columnResponse &&
            columnResponse?.reduce((acc, column) => {
                if (column.accessorKey && column.accessorKey !== "mrt-row-actions") {
                    acc[column.accessorKey] = column.is_visible ?? true;
                }
                return acc;
            }, {})
        );
    }, [columnResponse]);

    const renderGridView = () => (
        <>
            <Box sx={{ display: "flex", justifyContent: "flex-end", px: "10px" }}>
                <Tooltip title="Toggle List View">
                    <IconButton
                        onClick={() => setIsGridView((prev) => !prev)}
                    >
                        <GridView />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
                    gap: 2,
                }}
            >
                {rows.map((row) => (
                    <Box
                        key={row.id}
                        sx={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: 2,
                            textAlign: "center",
                        }}
                    >
                        <img
                            src={row.image_url || "/images/dholakia-logo.png"}
                            alt={row.diamond_name}
                            style={{ width: "100%", height: "150px", objectFit: "cover" }}
                        />
                        <Box mt={2}>
                            <h3>{row.diamond_name}</h3>
                            <p>Carat: {row.carat}</p>
                            <p>Price: ${row.price}</p>
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    );

    return (
        <React.Fragment>
            {/* Header Section */}
            {/* <Box 
              sx={{ 
                textAlign: "center", 
                py: 4,
                mb: 2,
                background: "linear-gradient(135deg, #f5f7fa, #c3cfe2)",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" 
              }}
            >
                <Typography 
                  variant="h2" 
                  component="h1" 
                  sx={{ 
                    fontWeight: "bold", 
                    color: "#333", 
                    fontSize: "2.5rem",
                    letterSpacing: "0.05em",
                    textTransform: "uppercase"
                  }}
                >
                    Special Cut
                </Typography>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    color: "#666", 
                    mt: 1,
                    fontStyle: "italic"
                  }}
                >
                    Discover the brilliance of perfection
                </Typography>
            </Box> */}

            <Box>
                <div style={{ height: `calc(100vh - ${boxHeight}px)`, width: "100%" }}>
                    {columnResponse && visibleColumn !== null ? (
                        isGridView ? (
                            renderGridView()
                        ) : (
                            <VirtualizedTableData
                                style={{ margin: 0 }}
                                height={`calc(100vh - ${boxHeight}px)`}
                                data={rows}
                                visibleColumn={visibleColumn}
                                loading={!loading && !viewColumnsFillAPI?.isPending}
                                columnResponse={columnResponse}
                                tfoot={false}
                                extraButtons={
                                    <Tooltip title="Toggle Grid View">
                                        <IconButton
                                            onClick={() => setIsGridView((prev) => !prev)}
                                        >
                                            <GridView />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        )
                    ) : null}
                </div>
            </Box>
        </React.Fragment>
    );
};

export default SpecialCut;
