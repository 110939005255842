import { Add } from "@mui/icons-material";
import { Box, Button, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import ConfirmationModal from "../components/confirmationModal";
import ScheduleForm from "./schedule-form";

const PartyStockSchedule = (props) => {
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterViewAPI = usePostData(`/master_view`);
    const deleteAPI = usePostData(`/party_stock_schedule_delete`);
    const [visibleColumn, setVisibleColumn] = useState({});
    const [columnResponse, setColumnResponse] = useState(null);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState({ flag: false, data: null });
    const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
    const [toastMess, setToastMess] = useState("");

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: props?.type_id,
                form_name: props?.form_name,
                view_name: "party_stock_schedule"
            });

            const columns = responseData?.map((col) => {
                const baseColumn = {
                    accessorKey: col.column_name,
                    header: col.column_header,
                    filterVariant: 'autocomplete',
                    size: col?.column_width,
                    columnType: col?.column_type,
                    filterFn: 'includesString',
                    is_visible: col?.is_visible,
                    is_visible_form: col?.is_visible_form,
                    is_editable: col?.is_editable,
                };

                // Add specific configuration for switch column type
                if (col.column_type === 'switch') {
                    return {
                        ...baseColumn,
                        filterVariant: 'boolean', // Example: Set filter variant for switch type
                        cell: ({ row }) => (
                            <Switch size="small"
                                checked={row.original[col.column_name]}
                                onChange={(e) => handleSwitchChange(e, row.original)}
                            />
                        )
                    };
                }

                return baseColumn;
            });

            const actionsColumn = {
                accessorKey: 'actions',
                header: 'Actions',
                size: 100,
                textAlign: 'center',
                columnType: 'action',
                is_visible: true,
                left: 0
            };

            columns.splice(0, 0, actionsColumn);
            setColumnResponse(columns);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Example of handling switch change
    const handleSwitchChange = (event, row) => {
        console.log('Switch toggled:', event.target.checked, 'Row:', row);
        // Implement your logic for switch change
    };


    const fetchRowData = async () => {
        try {
            const responseData = await masterViewAPI.mutateAsync({
                view_name: "party_stock_schedule",
            });

            if (responseData) {
                const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({ ...row, })) : [];

                setRows(rowsData);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchRowData();
        fetchColumn();
    }, []);

    useEffect(() => {
        setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
            if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
                acc[column.accessorKey] = column.is_visible ?? true;
            }
            return acc;
        }, {}))
    }, [columnResponse])

    const handleAddEdit = (data) => {
        if (data?.id) {
            setOpen({ flag: true, data: data });
        } else {
            setOpen({ flag: true, data: null });
        }
    };

    const deleteConfirmation = async (id) => {
        try {
            const responseData = await deleteAPI.mutateAsync({
                id: id?.id,
                party_code: id?.party_code
            });

            if (responseData?.success) {
                setRows((prevData) => prevData.filter((item) => item.id !== id?.id));
                setOpenDeletemodal({ flag: false, data: null });
                setToastMess(responseData.message); // Assuming responseData contains a message
            } else {
                setOpenDeletemodal({ flag: false, data: null });
                setToastMess(responseData.message); // Assuming responseData contains a message
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <React.Fragment>
            {(columnResponse && visibleColumn !== null) &&
                <VirtualizedTableData
                    style={{ margin: 0 }}
                    height={`calc(100vh - 35px)`}
                    data={rows} visibleColumn={visibleColumn}
                    loading={!viewColumnsFillAPI?.isPending}
                    columnResponse={columnResponse} tfoot={false}
                    uploadExcel={<Box><Button onClick={handleAddEdit} variant="contained" startIcon={<Add />}>Add</Button></Box>}
                    handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data })}
                    handleEdit={(data) => handleAddEdit(data)}
                    rowSelection={false} rowCount={rows?.length}
                />
            }
            {open?.flag && <ScheduleForm
                fields={columnResponse}
                open={open}
                onClose={() => setOpen({ flag: false, data: null })}
                type_id={props?.type_id}
                form_name={props?.form_name}
                view_name="party_stock_schedule"
                setRows={setRows}
                setToastMess={setToastMess}
            />}
            <ConfirmationModal
                open={openDeletemodal?.flag}
                onClose={() => setOpenDeletemodal({ flag: false, data: null })}
                header={"Delete Party?"}
                title={
                    <>Are you sure you want to delete the schedule?</>
                }
                action={() => deleteConfirmation(openDeletemodal?.data)}
            />
            {toastMess && <ToastBar open={toastMess} onClose={setToastMess} />}
        </React.Fragment>
    )
};

export default PartyStockSchedule;
