import * as React from "react";

const ExportExcel = ({ width = 18, height = 18, fill = "currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 50 50"
    fill={fill}  // Set fill color here
    {...props}
  >
    <path d="m28.813.031-28 5.313c-.473.09-.813.52-.813 1v37.312c0 .48.34.91.813 1l28 5.313c.062.011.125.031.187.031.23 0 .445-.07.625-.219.23-.191.375-.484.375-.781V1c0-.297-.145-.59-.375-.781-.23-.192-.52-.242-.813-.188ZM32 6v7h2v2h-2v5h2v2h-2v5h2v2h-2v6h2v2h-2v7h15c1.102 0 2-.898 2-2V8c0-1.102-.898-2-2-2Zm4 7h8v2h-8ZM6.687 15.688h5.125L14.5 21.28c.21.442.398.985.563 1.594h.03c.106-.363.31-.934.595-1.656l2.968-5.532h4.688l-5.594 9.25 5.75 9.438h-4.969l-3.25-6.094c-.12-.226-.246-.644-.375-1.25h-.031c-.063.285-.21.73-.438 1.313l-3.25 6.031h-5l5.97-9.344ZM36 20h8v2h-8Zm0 7h8v2h-8Zm0 8h8v2h-8Z" />
  </svg>
);

export default ExportExcel;
