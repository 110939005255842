import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import { usePostData } from "./common/function/api";
import BranchCompanyModal from "./components/branch-company-modal";
import ConfirmationModal from "./components/confirmationModal";
import Loader from "./components/loader";
const MRTable = lazy(() => import("./components/MRTable"));

const Company = React.memo(({ type_id, form_name }) => {
  const [columnResponse, setColumnResponse] = useState(null);
  const [visibleColumn, setVisibleColumn] = useState({});
  const [rows, setRows] = useState([]);
  const [loadComponent, setLoadComponent] = useState(false);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
  const fetchColumnAPI = usePostData(`/view_columns_fill`);
  const fetchDataAPI = usePostData(`/master_fill`);
  const createCompanyAPI = usePostData(`/company_save`);
  const deleteCompanyAPI = usePostData(`/company_delete`);

  useEffect(() => {
    const fetchColumn = () => {
      fetchColumnAPI.mutate({
        type_id: type_id,
        form_name: form_name
      }, {
        onSuccess: (responseData) => {
          setColumnResponse(responseData)
        },
        onError: (error) => {
          console.error('Error:', error); // Log the error
        },
      });
    }

    const fetchData = () => {
      fetchDataAPI.mutate({
        mast_name: form_name,
      }, {
        onSuccess: (responseData) => {
          const rowsData = responseData.map((row, index) => ({
            id: index + 1,
            ...row,
            is_active: row?.is_active
          }));
          setRows(rowsData);
          setLoadComponent(true)
        },
        onError: (error) => {
          console.error('Error:', error); // Log the error
        },
      });
    }

    fetchColumn();
    fetchData();
  }, [type_id, form_name]);

  const columns =
    columnResponse &&
    columnResponse.map((col) => ({
      accessorKey: col.column_name,
      header: col.column_header,
      size: col.column_width,
      filterVariant: 'autocomplete',
      flex: 1,
      filterFn: 'includesString',
      is_visible: col?.is_visible,
      is_visible_form: col?.is_visible_form,
      is_required: col?.is_required,
      css_class_name: col?.css_class_name,
      column_type: col?.column_type,
      muiTableBodyCellProps: ({ cell }) => ({
        // Custom rendering logic for the is_active column
        children:
          ["switch"]?.includes(col.column_type) ? (
            <label className="label toggle">
              <input type="checkbox" checked={cell.getValue()} className="toggle_input" />
              <div className="toggle-control"></div>
            </label>
          ) : (
            cell.getValue()
          ),
      }),
    }));

  useEffect(() => {
    setVisibleColumn(columns && columns?.reduce((acc, column) => {
      if (column.accessorKey) {
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const handleChange = (event, columnId, columnType) => {
    if (columnType === "select") {
      setFormData({ ...formData, [columnId]: event.target.value })
    } else if (columnType === "switch") {
      setFormData({ ...formData, [columnId]: event.target.checked })
    } else {
      setFormData({ ...formData, [columnId]: event.target.value })
    }
  };
  const renderDialogContent = (props, type) => (
    <BranchCompanyModal
      {...props}
      type={type}
      formData={formData}
      setFormData={setFormData}
      handleChange={handleChange}
      error={error}
      modalWidth="870px"
    />
  );
  // Function to handle creating a new row
  const createCompany = async ({ row, values, table }) => {
    const requestData = {
      company_data: {
        ...formData,
        ...(row?.original?.company_id && { company_id: formData?.company_id }),
      }
    }
    createCompanyAPI.mutate(requestData, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          const parseData = JSON?.parse(responseData?.request_body_data)
          if (row?.original?.company_id) {
            setRows((prevData) =>
              prevData.map((item) =>
                item.company_id === formData?.company_id
                  ? { ...formData }
                  : item
              )
            );
            setEditingRow(null);
            table.setEditingRow(null);
          } else {
            setRows((prevData) => [...prevData, {
              ...responseData, ...parseData?.company_data,
              company_id: responseData?.company_id,
              company_code: formData?.company_code,
              company_name: formData?.company_name
            }]);
            table.setCreatingRow(null);
          }
          setFormData({});
          setToastMess(responseData)
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      }
    });
  };

  const deleteConfirmation = async (id) => {
    deleteCompanyAPI.mutate({ company_id: id }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setRows((prevData) => prevData.filter((item) => item.company_id !== id));
          setOpenDeletemodal({ flag: false, data: null });
          setToastMess(responseData)
          setError("")
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      }
    });
  };

  useEffect(() => {
    if (editingRow) {
      const preparedData = {
        ...editingRow,
        is_active: editingRow?.is_active,
      }
      setFormData(preparedData);
    }
  }, [editingRow]);
  return (
    <React.Fragment>
      <div style={{ height: "100%", width: "100%" }}>
        {(columns?.length !== 0 && loadComponent && visibleColumn !== null) ? (
          <MRTable
            columns={columns || []}
            visibleColumn={visibleColumn}
            rows={rows || []}
            formData={formData}
            setFormData={setFormData}
            editingRow={editingRow}
            setEditingRow={setEditingRow}
            createFunction={createCompany}
            editFunction={createCompany}
            toastMess={toastMess}
            setToastMess={setToastMess}
            error={error}
            setError={setError}
            renderCreateRowDialogContent={(e) => renderDialogContent(e, "create")}
            renderEditRowDialogContent={(e) => renderDialogContent(e, "edit")}
            renderRowActions={({ row, table }) => (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                  <IconButton sx={{ padding: 0 }} onClick={() => {
                    setEditingRow(row?.original);
                    table.setEditingRow(row);
                  }}>
                    <Edit sx={{ height: '16px', width: '16px' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip sx={{ padding: 0 }} title="Delete">
                  <IconButton color="error" onClick={() => setOpenDeletemodal({ flag: true, data: row?.original })}>
                    <Delete sx={{ height: '16px', width: '16px' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          />
        ) : (
          <Loader />
        )}
      </div>
      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Company?"}
        title={
          <>Are you sure you want to delete <strong>"{openDeletemodal?.data?.company_name}"</strong> Company?</>
        }
        action={() => deleteConfirmation(openDeletemodal?.data?.company_id)}
      />
    </React.Fragment>
  );
});

export default Company;


