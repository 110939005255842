import React, { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import ToastBar from './ToastBar';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Download, Upload, UploadFile } from '@mui/icons-material';

const ExcelJsonViewer = ({ setJsonData, updateTableRows }) => {
    const [jsonItem, setJsonItem] = useState(null);
    const [toastMess, setToastMess] = useState(null);
    const fileInputRef = useRef(null);  // Step 1: Create a ref for the file input

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);

            if (json) {
                setJsonData(json);
                updateTableRows(json);
                setJsonItem(json);
                console.table(json);
                if (json?.length > 0) {
                    setToastMess("File uploaded successfully!");
                } else setToastMess("No record found in this file!");
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/images/sample.xlsx`;
        link.setAttribute('download', 'sample.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Box sx={{width: "100%"}}>
            <Button startIcon={<Upload/>} variant='contained' sx={{ my: 1 }} onClick={() => fileInputRef.current.click()}>
                Upload Excel
                <input
                    type="file"
                    onChange={handleFileUpload}
                    accept=".xls,.xlsx"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
            </Button>
            <Tooltip title="Download Sample">
                <IconButton onClick={handleDownload} sx={{mx: 0.5}}>
                    <Download />
                </IconButton>
            </Tooltip>
            <ToastBar open={toastMess} severity={jsonItem?.length > 0 ? "success" : "error"} onClose={() => setToastMess(null)} />
        </Box>
    );
};

export default ExcelJsonViewer;
