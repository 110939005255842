// api.js
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { APIURL } from '../constant';
import { getLocalStorage } from './common';

// Define a generic POST function with headers and token
const postData = async (url, data, token) => {
  const response = await axios.post(url, data, {
    headers: {
      'Authorization': `Bearer ${token}`,  // Include token in Authorization header
      'Content-Type': 'application/json'   // Ensure proper content type
    }
  });
  return response.data; // Ensure this returns the response data
};

// Custom hook to use for posting data
export const usePostData = (url) => {
  const IpAddress = useSelector((state) => state.setIpAddress); // Get IP address from Redux state
  const userData = getLocalStorage("userAuth"); // Get user data from local storage
  const token = userData?.token; // Extract token from user data

  return useMutation({
    mutationFn: (data) => postData(`${APIURL}${url}`, {
      ...data,
      ...(userData?.email && { user_name: userData?.email }),
      ...(IpAddress && { machine_name: IpAddress }),
      ...(userData?.user_id && { user_id: userData?.user_id }),
      ...(userData?.role_id && { role_id: userData?.role_id })
    }, token),  // Pass token to postData
  });
};


/** Here's an example of how you might use this hook in a component
 * mutation.isLoading
 * mutation.isError
 * mutation.error.message
 * mutation.isSuccess
*/