import React from 'react';

// The ImagePreviewPopup component now handles both images and PDFs.
const ImagePreviewPopup = ({ isOpen, selectedImage, fileType = "image", closePreview }) => {
  if (!isOpen) return null;

  return (
    <div className="popup" onClick={closePreview}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={closePreview}>
          &times;
        </span>

        {/* Conditionally render an image or a PDF based on fileType */}
        {fileType === 'image' ? (
          <img src={selectedImage} alt="Full preview" className="full-image" />
        ) : fileType === 'pdf' ? (
          <iframe
            src={selectedImage}
            width="500px"
            height="740px"
            title="PDF Preview"
            style={{ border: "5px solid white", borderRadius: "5px" }}
            frameBorder="0"
          />
        ) : (
          <p>Unsupported file format</p>
        )}
      </div>
    </div>
  );
};

export default ImagePreviewPopup;
