import { Autocomplete, Box, Button, DialogContent, FormControl, InputLabel, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import { convertToDayjs, createLabelValue } from "../common/function/common";
import ConfirmationModal from "../components/confirmationModal";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";
import EditSaleDiamond from "../sale-view/edit-sale-diamond";
import DCustomDatePicker from "../components/DCustomDatePicker";

const SaleView = React.memo(({ open, onClose, setMemoRows }) => {
  const boxRef = useRef(null);
  const masterFillAPI = usePostData(`/master_fill`);
  const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
  const saleViewAPI = usePostData(`/sale_view`);
  const saveAPI = usePostData(`/sale_save`);
  const deleteAPI = usePostData(`/sale_delete`);
  const advantageAPI = usePostData(`/price_advantage_view`);
  const [columns, setColumns] = useState([]);
  const [selectData, setSelectData] = useState({
    memo_type_name: [],
    party_name: [],
    broker_name: [],
    party_member_name: [],
    reason_name: [],
    fianancial_year_name: [],
    bill_type_name: [],
    bill_party_name: [],
    sale_person_name: [],
    adat_name: [],
    sale_through_name: [],
  });
  const [visibleColumn, setVisibleColumn] = useState({});
  const [columnResponse, setColumnResponse] = useState(null);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [selectedDates, setSelectedDates] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [toastMess, setToastMess] = useState(null);
  const [openSaleForm, setOpenSaleForm] = useState(null);
  const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });

  const fetchRows = async () => {
    setLoading(true);
    try {
      const responseData = await saleViewAPI.mutateAsync({
        "trans_name": !open?.auto_id ? "sale_diamond_detail_by_id" : "sale_data_by_no",
        "auto_id": open?.auto_id ?? 0,
        ...(!open?.auto_id && { "diamond_id": open?.join(",") })
      });
      let responseDatas = responseData?.detail ?? responseData
      const rowsData = responseDatas?.length !== 0 ? responseDatas?.map((row, index) => ({
        id: index + 1,
        ...row
      })) : [];
      setRows(rowsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  const fetchColumn = async () => {
    try {
      const responseData = await viewColumnsFillAPI.mutateAsync({
        type_id: 0,
        form_name: "sale",
        view_name: "sale_detail",
      });
      const alignmentMap = {
        3: 'right',
        2: 'center',
        1: 'left'
      };
      const columns = responseData?.map((col) => ({
        accessorKey: col.column_name,
        header: col.column_header,
        filterVariant: 'autocomplete',
        size: col?.column_width,
        columnType: col?.column_type,
        textAlign: alignmentMap[col?.column_align],
        filterFn: 'includesString',
        is_visible: col?.is_visible,
        is_visible_form: col?.is_visible_form,
        is_editable: col?.is_editable
      }));
      const actionsColumn = {
        accessorKey: 'actions',
        header: 'Actions',
        size: 100, // Adjust size if needed
        textAlign: 'center',
        columnType: 'action', // Custom type
        is_visible: true,
        left: 0
      };
      columns.splice(0, 0, actionsColumn);
      setColumnResponse(columns);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchData = async (mastType, mastName, key) => {
    try {
      const responseData = await masterFillAPI.mutateAsync({
        "mast_type": mastType,
        "mast_name": mastName
      });

      return { [key]: responseData };
    } catch (error) {
      console.error('Error:', error);
      return { [key]: [] };
    }
  };

  const fetchAllData = async () => {
    try {
      const [memoTypeName, partyName, brokerName, memoReasonName, fianancialYear, billType, billParty, salePerson, adatName, saleThrough] = await Promise.all([
        fetchData("select", "memo_type", "memo_type_name"),
        fetchData("select", "party", "party_name"),
        fetchData("select", "broker", "broker_name"),
        fetchData("select", "memo_reason", "reason_name"),
        fetchData("select", "fianance_year", "fianancial_year_name"),
        fetchData("select", "bill_type", "bill_type_name"),
        fetchData("select", "party", "bill_party_name"),
        fetchData("select", "sale_person", "sale_person_name"),
        fetchData("select", "adat", "adat_name"),
        fetchData("select", "sale_through", "sale_through_name"),
      ]);

      setSelectData({
        ...memoTypeName, ...partyName, ...brokerName, ...memoReasonName, ...fianancialYear, ...billType, ...billParty, ...salePerson, ...adatName, ...saleThrough
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchPartyData = (party_code, type) => {
    masterFillAPI.mutate({
      "mast_type": "select",
      "mast_name": "party_member",
      party_code: party_code
    }, {
      onSuccess: (responseData) => {
        setSelectData((prevData) => ({
          ...prevData,
          party_member_name: responseData
        }));
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const saveMemo = () => {
    saveAPI.mutate({
      ...formData,
      auto_id: formData?.auto_id ?? 0,
      diamond_data_list: rows,
      party_code: formData?.party_name?.value,
      memo_type_id: formData?.memo_type_name?.value,
      reason_id: formData?.reason_name?.value,
      party_member_id: formData?.party_member_name?.value,
      broker_code: formData?.broker_name?.value,
      fianancial_year_id: formData?.fianancial_year_name?.value,
      bill_type_id: formData?.bill_type_name?.value,
      bill_party_code: formData?.bill_party_name?.value,
      sale_person_id: formData?.sale_person_name?.value,
      adat_code: formData?.adat_name?.value,
      sale_through_id: formData?.sale_through_name?.value,
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          const parseData = JSON?.parse(responseData?.request_body_data)
          if (formData?.auto_id) {
            setMemoRows((prevData) =>
              prevData.map((item) =>
                item.auto_id === formData?.auto_id
                  ? {
                    ...parseData,
                    ...formData,
                    party_name: parseData?.party_name?.label,
                    memo_type_name: parseData?.memo_type_name?.label,
                    reason_name: parseData?.reason_name?.label,
                    party_member_name: parseData?.party_member_name?.label,
                    broker_name: parseData?.broker_name?.label,
                    fianancial_year_name: parseData?.fianancial_year_name?.label,
                    bill_type_name: parseData?.bill_type_name?.label,
                    bill_party_name: parseData?.bill_party_name?.label,
                    sale_person_name: parseData?.sale_person_name?.label,
                    adat_name: formData?.adat_name?.label,
                    sale_through_name: formData?.sale_through_name?.label,
                  }
                  : item
              )
            );
          }
          setToastMess(responseData?.message)
          handleClose()
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
        setError(error);
      }
    });
  }

  useEffect(() => {
    if (open?.auto_id || open?.length) {
      fetchColumn();
      fetchRows();
    }
  }, [open?.auto_id, open?.length]);

  useEffect(() => {
    if (formData?.bill_party_name?.value) {
      fetchPartyData(formData.bill_party_name.value, 'bill_party_member');
    } else if (formData?.party_name?.value) {
      fetchPartyData(formData.party_name.value, 'party_member_name');
    }
  }, [formData?.bill_party_name?.value, formData?.party_name?.value]);

  useEffect(() => {
    const fetchSaleColumn = async () => {
      try {
        const responseData = await viewColumnsFillAPI.mutateAsync({
          "type_id": 0,
          "form_name": "sale",
          "view_name": "sale_summary"
        });

        if (responseData) {
          setColumns(responseData.sort((a, b) => a.form_display_ord - b.form_display_ord));
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchSaleColumn();
    fetchAllData();
  }, []);

  useEffect(() => {
    setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
      if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
        acc[column.accessorKey] = column.is_visible ?? true;
      }
      return acc;
    }, {}))
  }, [columnResponse])

  const handleEdit = (data) => {
    setOpenSaleForm(data)
  }

  const handleAdvantage = async (type) => {
    advantageAPI.mutate({
      view_name: type,
      sale_date: formData?.sale_date,
      diamond_data_list: rows,
      ...(formData?.less1_percent && { less1_percent: formData?.less1_percent }),
      ...(formData?.less2_percent && { less2_percent: formData?.less2_percent })
    }, {
      onSuccess: (responseData) => {
        if (responseData) {
          setRows(responseData);
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const handleDelete = async (data) => {
    deleteAPI.mutate({
      auto_id: data?.auto_id,
      diamond_id_list: data?.diamond_id
    }, {
      onSuccess: (responseData) => {
        if (responseData?.success) {
          setRows((prevData) => prevData.filter((item) => item.diamond_id !== data?.diamond_id));
          setToastMess(responseData?.message)
          setOpenDeletemodal({ flag: false, data: null })
          setError("")
        } else {
          setError(responseData?.message);
        }
      },
      onError: (error) => {
        console.error('Error:', error);
      },
    });
  };

  const handleClose = () => {
    onClose(null);
    setError('')
    setFormData({})
  };

  const addDaysToDate = (date, days) => {
    if (date) {
      const result = dayjs(date).add(days, 'day'); // Use Day.js to add days
      return result.format('DD-MM-YYYY'); // Format the resulting date
    }
  };

  const handleChange = (newValue, columnId, columnType) => {
    if (columnType === "select") {
      if (columnId === "party_name") {
        setFormData({
          ...formData,
          [columnId]: newValue,
          ...(!formData?.bill_party_name && { party_member_name: null }),
        });
      } else if (columnId === "bill_party_name") {
        setFormData({
          ...formData,
          [columnId]: newValue,
          party_member_name: null,
        });
      } else if (columnId === "broker_name") {
        setFormData({
          ...formData,
          [columnId]: newValue,
        });
      } else {
        setFormData({ ...formData, [columnId]: newValue });
      }
    } else if (columnType === "switch") {
      setFormData({ ...formData, [columnId]: newValue.target.checked });
    } else {
      setFormData({ ...formData, [columnId]: newValue.target.value });
      if (columnId === "due_day") {
        const value = newValue.target.value;

        // Check if the entered value is "0" and prevent update
        if (value === "0") return;

        if (selectedDates['sale_date'] && value) {
          const newResultDate = addDaysToDate((convertToDayjs(formData?.sale_date) || selectedDates['sale_date']), value);
          setSelectedDates({
            ...selectedDates,
            due_date: convertToDayjs(newResultDate)
          });
          setFormData((prevFormData) => ({
            ...prevFormData,
            due_date: newResultDate
          }));
        }
      }
    }
  };

  useEffect(() => {
    if (open?.auto_id) {
      setFormData({
        ...open,
        ...(open?.party_code && { party_name: createLabelValue(open?.party_name, open?.party_code) }),
        ...(open?.memo_type_id && { memo_type_name: createLabelValue(open?.memo_type_name, open?.memo_type_id) }),
        ...(open?.reason_id && { reason_name: createLabelValue(open?.reason_name, open?.reason_id) }),
        ...(open?.party_member_id && { party_member_name: createLabelValue(open?.party_member_name, open?.party_member_id) }),
        ...(open?.broker_code && { broker_name: createLabelValue(open?.broker_name, open?.broker_code) }),
        ...(open?.fianancial_year_id && { fianancial_year_name: createLabelValue(open?.fianancial_year_name, open?.fianancial_year_id) }),
        ...(open?.bill_type_id && { bill_type_name: createLabelValue(open?.bill_type_name, open?.bill_type_id) }),
        ...(open?.bill_party_code && { bill_party_name: createLabelValue(open?.bill_party_name, open?.bill_party_code) }),
        ...(open?.sale_person_id && { sale_person_name: createLabelValue(open?.sale_person_name, open?.sale_person_id) }),
        ...(open?.adat_code && { adat_name: createLabelValue(open?.adat_name, open?.adat_code) }),
        ...(open?.sale_through_id && { sale_through_name: createLabelValue(open?.sale_through_name, open?.sale_through_id) }),
      })
      setSelectedDates({
        ...selectedDates,
        trn_date: convertToDayjs(open?.trn_date),
        sale_date: convertToDayjs(open?.sale_date),
        due_date: convertToDayjs(open?.due_date)
      });
    }
  }, [open?.auto_id])

  const sumRow = {};
  const rowsToSum = rows;
  const numberColumns = ['diamond_id', 'pcs', 'carat', 'rap_amount', 'disc', 'rate', 'rate_amount', 'sale_disc', 'sale_rate', 'sale_amount']
  numberColumns && numberColumns.forEach(column => {
    sumRow[column] = rowsToSum.reduce((acc, row) => acc + Number(row[column] || 0), 0).toFixed(2);
  });

  const totalRateAmount = rowsToSum.reduce((acc, row) => acc + Number(row['rate_amount'] || 0), 0);
  const totalRapAmount = rowsToSum.reduce((acc, row) => acc + Number(row['rap_amount'] || 0), 0);
  const totalCarat = rowsToSum.reduce((acc, row) => acc + Number(row['carat'] || 0), 0);
  const totalSaleAmount = rowsToSum.reduce((acc, row) => acc + Number(row['sale_amount'] || 0), 0);

  const disc = ((totalRateAmount / totalRapAmount) * 100) - 100;
  const rate = totalRateAmount / totalCarat;
  const sale_disc = ((totalSaleAmount / totalRapAmount) * 100) - 100;
  const sale_rate = totalSaleAmount / totalCarat;

  sumRow['diamond_id'] = rows?.length;
  sumRow['disc'] = disc.toFixed(2);
  sumRow['rate'] = rate.toFixed(2);
  sumRow['sale_disc'] = sale_disc.toFixed(2);
  sumRow['sale_rate'] = sale_rate.toFixed(2);

  return (
    <React.Fragment>
      <DModal
        open={Boolean(open)}
        backDrop
        onClose={handleClose}
        DialogTitle={"Sale"}
        DialogContent={
          <>
            <Box sx={{ overflowY: "auto", overflowX: "hidden" }}>
              <DialogContent ref={boxRef} className="d_row" sx={{ padding: "20px 24px 5px 24px !important" }}>
                {
                  columns &&
                  columns?.filter(_ => _?.is_visible_form)?.map((item, index) => {
                    const columnType = item?.column_type;
                    const required = item?.is_required;
                    const columnKey = item?.column_name;
                    const cssClassName = item?.css_class_name;
                    const headerName = item?.column_header;
                    const disabled = item?.is_editable;
                    const isFirstField = index === 2;

                    if (columnType === "text") {
                      return (
                        <div className={cssClassName}>
                          <DTextField
                            key={index}
                            label={headerName}
                            required={required}
                            autoFocus={isFirstField}
                            value={formData[columnKey] || ''}
                            type={columnKey === "due_day" ? "number" : "text"}
                            onChange={(e) => handleChange(e, columnKey, columnType)}
                            variant="outlined"
                            fullWidth
                            size="small"
                            disabled={!disabled}
                          />
                        </div>
                      );
                    }
                    if (columnType === 'select') {
                      let options = [];
                      options = selectData[columnKey];
                      return (
                        <div className={cssClassName}>
                          <FormControl fullWidth key={columnKey}>
                            <Autocomplete
                              value={formData[columnKey] || null}
                              autoFocus={isFirstField}
                              onChange={(event, newValue) => handleChange(newValue, columnKey, 'select')}
                              options={options || []}
                              disabled={!disabled}
                              getOptionLabel={(option) => option?.label ?? ""}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required={required}
                                  autoFocus={isFirstField}
                                  label={headerName}
                                  fullWidth
                                />
                              )}
                              size="small"
                              isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            />

                          </FormControl>
                        </div>
                      );
                    }
                    if (columnType === 'date') {
                      const handleDateChange = (newValue, columnKey) => {
                        setSelectedDates((prevDates) => ({
                          ...prevDates,
                          [columnKey]: newValue,
                        }));

                        const formattedDate = newValue ? newValue.format('DD-MM-YYYY') : '';

                        setFormData((prevData) => ({ ...prevData, [columnKey]: formattedDate }));
                        if (formData?.due_day) {
                          const calculatedDate = addDaysToDate(newValue, formData?.due_day);
                          setSelectedDates((prevData) => ({ ...prevData, due_date: convertToDayjs(calculatedDate) }));
                          setFormData((prevData) => ({ ...prevData, due_date: convertToDayjs(calculatedDate) }));
                        }
                      };

                      return (
                        <div className={`${cssClassName} member-datepicker`}>
                          <DCustomDatePicker
                            headerName={headerName}
                            required={required}
                            selectedDate={selectedDates[columnKey]}
                            onChange={(newValue) => handleDateChange(newValue, columnKey)}
                            columnKey={columnKey}
                            disabled={!disabled}
                          />
                        </div>
                      );
                    }
                  })
                }
                {error && <em style={{ color: "red" }}>{error}</em>}
              </DialogContent>
              <Box>
                <div style={{ height: "100%", width: "100%" }}>
                  {(columnResponse && visibleColumn !== null) &&
                    <VirtualizedTableData
                      height={"380px"} rowSelection={false}
                      data={rows} visibleColumn={visibleColumn}
                      loading={!loading && !viewColumnsFillAPI?.isPending}
                      columnResponse={columnResponse}
                      rowCount={rows?.length}
                      handleDelete={(data) => setOpenDeletemodal({ flag: true, data: data })}
                      handleEdit={(data) => handleEdit(data)} memoSumRow={sumRow}
                      numberColumns={numberColumns}
                      totalCountColumn={'diamond_id'}
                    />}
                </div>
              </Box>
            </Box>
          </>
        }
        DialogActions={
          <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1}>
              {formData?.sale_date && <>
                <Button variant="contained" onClick={() => handleAdvantage("advantage_by_amount")}>ADV</Button>
                <Button variant="contained" onClick={() => handleAdvantage("additional_advantage_by_amount")}>AADV</Button>
              </>}
              <DTextField
                label={"Less1 %"}
                value={formData?.less1_percent || ''}
                type="number"
                onChange={(e) => setFormData({ ...formData, less1_percent: Number(e.target.value) })}
                variant="outlined"
                fullWidth
                size="small" />
              <DTextField
                label={"Less2 %"}
                value={formData?.less2_percent || ''}
                type="number"
                onChange={(e) => setFormData({ ...formData, less2_percent: Number(e.target.value) })}
                variant="outlined"
                fullWidth
                size="small" />
              <Button variant="contained" onClick={() => handleAdvantage("less_by_amount")}>Apply</Button>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" autoFocus onClick={saveMemo}>Save</Button>
            </Box>
          </Box>
        }
      />
      <EditSaleDiamond open={openSaleForm} onClose={setOpenSaleForm} setMemoRows={setRows} />
      <ConfirmationModal
        open={openDeletemodal?.flag}
        onClose={() => setOpenDeletemodal({ flag: false, data: null })}
        header={"Delete Sale?"}
        title={
          <>Are you sure you want to delete this <strong>"{openDeletemodal?.data?.diamond_id}"</strong> sale?</>
        }
        action={() => handleDelete(openDeletemodal?.data)}
      />
      <ToastBar open={toastMess} onClose={setToastMess} />
    </React.Fragment>
  );
});

export default SaleView;
