import { Delete, Edit, PictureAsPdf } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, DialogContent, FormControl, FormControlLabel, IconButton, InputLabel, Switch, TextField, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { MRT_EditActionButtons } from "material-react-table";
import React, { lazy, useEffect, useState } from "react";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";
import AvatarUpload from "../components/ImageEditor";
import CountryCodeWithPhoneInput from "../components/MobileCountrySelect";
import { usePostData } from "./../common/function/api";
import { convertToDayjs, getExtensionFromBase64, getPartyDataFromUrl } from "./../common/function/common";
import ConfirmationModal from "./../components/confirmationModal";
import ImagePreviewPopup from "./../components/ImagePreviewPopup";
import Loader from "./../components/loader";
import ToastBar from "./../components/ToastBar";
import DCustomDatePicker from "../components/DCustomDatePicker";
const MRTable = lazy(() => import("./../components/MRTable"));

const Employee = React.memo(({ type_id, form_name }) => {
    const partyData = getPartyDataFromUrl("data");
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const masterViewAPI = usePostData(`/master_view`);
    const masterFillAPI = usePostData(`/master_fill`);
    const saveAPI = usePostData(`/employee_save`);
    const deleteAPI = usePostData(`/employee_delete`);
    const [columnResponse, setColumnResponse] = useState(null);
    const [visibleColumn, setVisibleColumn] = useState({});
    const [selectOptionsData, setSelectOptionsData] = useState({
        isd_code_name: [],
        designation_name: [],
        gender_name: [],
        location_name: [],
        role_name: [],
    });
    const [rows, setRows] = useState([]);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState('');
    const [toastMess, setToastMess] = useState(null);
    const [editingRow, setEditingRow] = useState(null);
    const [selectedDates, setSelectedDates] = useState({});
    const [openDeletemodal, setOpenDeletemodal] = useState({ flag: false, data: null });
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [fileType, setFileType] = useState('');
    const [open, setOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleImgClose = () => setOpen(false);

    const handleImageCropped = (image) => {
        setCroppedImage(image);
    };


    const openPreview = (image, type) => {
        setSelectedImage(image);
        setFileType(type);
        setIsOpen(true);
    };

    const closePreview = () => {
        setIsOpen(false);
        setSelectedImage('');
        setFileType('');
    };

    const fetchMasterData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_type": mastType,
                "mast_name": mastName
            });
            return { [key]: responseData };
        } catch (error) {
            console.error('Error:', error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [isdCode, gender, designation, location, role] = await Promise.all([
                fetchMasterData("select", "isd_code", "isd_code_name"),
                fetchMasterData("select", "gender", "gender_name"),
                fetchMasterData("select", "designation", "designation_name"),
                fetchMasterData("select", "country", "location_name"),
                fetchMasterData("select", "role", "role_name"),
            ]);

            setSelectOptionsData({
                ...isdCode, ...gender, ...designation, ...location, ...role
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchFieldData = {
        type_id: type_id,
        form_name: form_name,
    };

    const fetchRefData = {
        party_code: partyData?.party_code,
        view_name: "employee",
    };

    const fetchColumn = () => {
        viewColumnsFillAPI.mutate(fetchFieldData, {
            onSuccess: (responseData) => {
                const columns = responseData?.map((col) => ({
                    accessorKey: col.column_name,
                    header: col.column_header,
                    size: col.column_width,
                    filterVariant: 'autocomplete',
                    flex: 1,
                    filterFn: 'includesString',
                    is_visible: col?.is_visible,
                    is_visible_form: col?.is_visible_form,
                    is_required: col?.is_required,
                    css_class_name: col?.css_class_name,
                    column_type: col?.column_type,
                    form_display_ord: col?.form_display_ord,
                    isUppercase: col?.is_text_upper,
                    muiTableBodyCellProps: ({ cell }) => ({
                        sx: {
                            textAlign: ["image"]?.includes(col.column_type) ? "center" : "left",
                        },
                        children:
                            ["switch"]?.includes(col.column_type) ? (
                                <label className="label toggle">
                                    <input type="checkbox" checked={cell.getValue()} className="toggle_input" />
                                    <div className="toggle-control"></div>
                                </label>
                            ) : ["image"]?.includes(col.column_type) ?
                                (getExtensionFromBase64(cell.getValue()) !== "pdf" ? <img onDoubleClick={() => cell.getValue() && openPreview(cell.getValue())}
                                    style={{ width: "70px", height: "70px", cursor: "pointer", borderRadius: "3px", background: "#8993a3", objectFit: "cover" }}
                                    src={cell.getValue() || "/images/default-avatar.png"}
                                /> :
                                    <Button onDoubleClick={() => cell.getValue() && openPreview(cell.getValue(), 'pdf')} component="span" variant="outlined" sx={{ background: "white", minWidth: "40px", padding: "7px 0", borderColor: "#0000003b" }}>
                                        <PictureAsPdf htmlColor="red" />
                                    </Button>) : (
                                    cell.getValue()
                                ),
                    }),
                }));
                setColumnResponse(columns)
            },
            onError: (error) => {
                console.error('Error:', error);
            },
        });
    }

    const fetchData = () => {
        masterViewAPI.mutate(fetchRefData, {
            onSuccess: (responseData) => {
                const rowsData = responseData?.length !== 0 ? responseData?.map((row, index) => ({
                    id: index + 1,
                    ...row
                })) : [];
                setRows(rowsData);
            },
            onError: (error) => {
                console.error('Error:', error);
            },
        });
    }

    useEffect(() => {
        fetchColumn();
        fetchData();
        fetchAllData();
    }, [type_id, form_name]);

    const handleChange = (event, columnId, columnType) => {
        if (columnType === "contact") {
            setFormData({
                ...formData,
                [columnId]: {
                    ...formData[columnId],
                    number: event.target.value
                }
            });
        } else if (columnType === "switch") {
            setFormData({ ...formData, [columnId]: event.target.checked });
        } else setFormData({ ...formData, [columnId]: event.target.value })
    };

    const handleClose = (table) => {
        setEditingRow(null);
        setFormData({});
        setSelectedDates({});
        setCroppedImage(null);
        setOpenDeletemodal({ flag: false, data: null });
        table.setCreatingRow(null);
        table.setEditingRow(null);
    };

    const renderDialogContent = ({ table, row, internalEditComponents }) => {
        const isCreate = !row?.original?.id;
        return (
            <DModal
                open={true}
                onClose={() => handleClose(table)}
                DialogTitle={isCreate ? 'Add New' : "Update"}
                DialogContent={
                    <DialogContent className="d_row" sx={{ padding: "20px 24px 5px 24px !important" }}>
                        {internalEditComponents?.filter(_ => _?.props?.cell?.column?.columnDef?.is_visible_form).sort((a, b) => a?.props?.cell?.column?.columnDef?.form_display_ord - b?.props?.cell?.column?.columnDef?.form_display_ord).map((component, index) => {
                            const columnKey = component?.props?.cell?.column?.id;
                            const columnType = component?.props?.cell?.column?.columnDef?.column_type;
                            const required = component?.props?.cell?.column?.columnDef?.is_required;
                            const cssClassName = component?.props?.cell?.column?.columnDef?.css_class_name;
                            const headerName = component.props.cell.column.columnDef.header;
                            const selectOption = selectOptionsData[columnKey] || [];
                            const isFirstField = index === 1;

                            if (columnType === 'date') {
                                const handleChange = (newValue) => {
                                    setSelectedDates((prevDates) => ({
                                        ...prevDates,
                                        [columnKey]: newValue,
                                    }));
                                    const formattedDate = newValue ? newValue.format('DD-MM-YYYY') : '';
                                    setFormData((prevData) => ({ ...prevData, [columnKey]: formattedDate }));
                                };

                                return (
                                    <div className={`${cssClassName} member-datepicker`}>
                                        <DCustomDatePicker
                                            headerName={headerName}
                                            required={required}
                                            selectedDate={selectedDates[columnKey]}
                                            onChange={handleChange}
                                            columnKey={columnKey}
                                        />
                                    </div>
                                );
                            }

                            if (columnType === 'contact') {
                                return (
                                    <div className={cssClassName} key={columnKey}>
                                        <CountryCodeWithPhoneInput
                                            names={selectOptionsData?.isd_code_name}
                                            selectedCode={formData[columnKey] || { value: '', label: '' }}
                                            phoneNumber={formData[columnKey]?.number || ''}
                                            handleChange={handleChange}
                                            columnKey={columnKey}
                                            required={required}
                                            columnType={columnType}
                                            headerName={headerName}
                                        />
                                    </div>
                                );
                            }

                            if (columnType === "select") {
                                return (
                                    <div className={cssClassName} key={index}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                value={formData[columnKey] || null}
                                                onChange={(event, newValue) => handleChange({ target: { value: newValue } }, columnKey, columnType)}
                                                options={selectOption || []}
                                                getOptionLabel={(option) => option.label || ""}
                                                autoFocus={isFirstField}
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        autoFocus={isFirstField}
                                                        required={required}
                                                        {...params}
                                                        label={headerName}
                                                        fullWidth
                                                    />
                                                )}
                                                isOptionEqualToValue={(option, value) => option.value === value?.value}
                                            />

                                        </FormControl>
                                    </div>
                                )
                            }
                            if (columnType === "text" || columnType === "password") {
                                return (
                                    <div className={cssClassName}>
                                        <DTextField
                                            key={index}
                                            label={headerName}
                                            type={columnType}
                                            isUppercase={component?.props?.cell?.column?.columnDef?.isUppercase}
                                            required={required}
                                            value={formData[columnKey] || ''}
                                            onChange={(e) => handleChange(e, columnKey, columnType)}
                                            variant="outlined"
                                            fullWidth
                                            autoFocus={isFirstField}
                                            size="small"
                                            disabled={columnKey === 'id'}
                                        />
                                    </div>
                                );
                            }

                            if (columnType === "switch") {
                                return (
                                    <div className={cssClassName}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    autoFocus={isFirstField}
                                                    checked={formData[columnKey] || false}
                                                    onChange={(e) => handleChange(e, columnKey, columnType)}
                                                />
                                            }
                                            label={headerName}
                                        />
                                    </div>
                                )
                            }
                            if (columnType === "image") {
                                return (
                                    <div style={{ height: "40px", cursor: "pointer", borderRadius: "3px", margin: "0 10px" }}>
                                        {!croppedImage &&
                                            <img style={{ height: "40px", cursor: "pointer", borderRadius: "3px", background: "#8993a3" }} onClick={handleOpen} src={formData[columnKey] || "/images/default-avatar.png"} />}
                                        <AvatarUpload
                                            open={open}
                                            onClose={handleImgClose}
                                            onImageCropped={handleImageCropped}
                                            initialImage={formData[columnKey]}
                                        />
                                        {croppedImage && (
                                            <img onClick={handleOpen} src={croppedImage} alt="Cropped" style={{ maxWidth: '100%', height: "40px", objectFit: "cover", borderRadius: "3px" }} />
                                        )}
                                    </div>
                                );
                            }
                            return React.cloneElement(component, { key: index });
                        })}
                        {error && <em style={{ color: "red", paddingLeft: "10px" }}>{error}</em>}
                    </DialogContent>
                }
                DialogActionsclassName={saveAPI?.isPending ? "d_disabled d_relative" : ""}
                DialogActions={
                    <>
                        {saveAPI?.isPending && <CircularProgress size={20} style={{ color: "white", position: "absolute", top: "18px", right: "50px", zIndex: 1 }} />}
                        <MRT_EditActionButtons variant="text" table={table} row={row} />
                    </>
                }
            />
        )
    };

    const createDocument = ({ row, values, table }) => {
        const preparedData = {
            employee_data: {
                ...formData,
                isd_code_id: formData?.contact_no?.value,
                designation_id: formData?.designation_name?.value,
                location_id: formData?.location_name?.value,
                role_id: formData?.role_name?.value,
                gender_id: formData?.gender_name?.value,
                contact_no: formData?.contact_no?.number,
                photo: croppedImage ? croppedImage : formData?.photo,
                ...(formData?.id && { id: formData?.id })
            },
        };

        saveAPI.mutate(preparedData, {
            onSuccess: (responseData) => {
                if (responseData?.success) {
                    const parseData = JSON?.parse(responseData?.request_body_data)
                    setToastMess(responseData?.message);
                    if (editingRow?.id) {
                        setRows((prevRows) =>
                            prevRows.map((row) =>
                                row.id === editingRow?.id ? {
                                    ...formData,
                                    id: responseData?.id,
                                    name: responseData?.name,
                                    designation_name: formData?.designation_name?.label,
                                    location_name: formData?.location_name?.label,
                                    role_name: formData?.role_name?.label,
                                    gender_name: formData?.gender_name?.label,
                                    isd_code_name: formData?.isd_code_name?.label,
                                    contact_no: formData?.contact_no?.number,
                                    photo: croppedImage ? croppedImage : formData?.photo,
                                } : row
                            )
                        );
                        table.setEditingRow(null);
                    } else {
                        setRows((prevRows) => [...prevRows, {
                            id: prevRows.length + 1, ...formData, ...parseData?.employee_data, name: responseData?.name,
                            designation_name: formData?.designation_name?.label,
                            location_name: formData?.location_name?.label,
                            role_name: formData?.role_name?.label,
                            gender_name: formData?.gender_name?.label,
                            isd_code_name: formData?.isd_code_name?.label,
                            contact_no: formData?.contact_no?.number,
                            photo: croppedImage ? croppedImage : formData?.photo,
                        }]);
                        table.setCreatingRow(null);
                    }
                    setEditingRow(null);
                    setFormData({});
                    setSelectedDates({});
                    setCroppedImage(null);
                } else setError(responseData?.message)
            },
            onError: (error) => {
                console.error('Error:', error);
            },
        });
    };

    const deleteConfirmation = async (id) => {
        deleteAPI.mutate({
            id: id,
        }, {
            onSuccess: (responseData) => {
                setRows((prevData) => prevData.filter((item) => item.id !== id));
                setOpenDeletemodal({ flag: false, data: null });
                setToastMess(responseData?.message)
                setError("")
            },
            onError: (error) => {
                console.error('Error:', error);
            },
        });
    };

    useEffect(() => {
        if (editingRow) {
            const preparedData = {
                ...editingRow,
                contact_no: { label: editingRow?.isd_code_name, value: editingRow?.isd_code_id, number: editingRow?.contact_no },
                designation_name: { label: editingRow?.designation_name, value: editingRow?.designation_id },
                location_name: { label: editingRow?.location_name, value: editingRow?.location_id },
                role_name: { label: editingRow?.role_name, value: editingRow?.role_id },
                gender_name: { label: editingRow?.gender_name, value: editingRow?.gender_id },
            }
            setSelectedDates({
                birthdate: convertToDayjs(editingRow?.birthdate),
                join_date: convertToDayjs(editingRow?.join_date),
                leave_date: convertToDayjs(editingRow?.leave_date),
            });
            setFormData(preparedData);
        }
    }, [editingRow]);

    useEffect(() => {
        setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
            if (column.accessorKey) {
                acc[column.accessorKey] = column.is_visible ?? true;
            }
            return acc;
        }, {}))
    }, [columnResponse])

    return (
        <React.Fragment>
            <div style={{ height: "100%", width: "100%" }}>
                {!viewColumnsFillAPI?.isPending && visibleColumn !== null ? (
                    <MRTable
                        columns={columnResponse || []}
                        visibleColumn={visibleColumn}
                        rows={rows || []}
                        formData={formData}
                        setFormData={setFormData}
                        editingRow={editingRow}
                        setEditingRow={setEditingRow}
                        createFunction={createDocument}
                        editFunction={createDocument}
                        toastMess={toastMess}
                        setToastMess={setToastMess}
                        error={error}
                        maxHeight="840px"
                        setError={setError}
                        onOtherClose={() => {
                            setSelectedDates({})
                            setCroppedImage(null)
                        }}
                        renderCreateRowDialogContent={(e) => renderDialogContent(e, "create")}
                        renderEditRowDialogContent={(e) => renderDialogContent(e, "edit")}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                <Tooltip title="Edit">
                                    <IconButton sx={{ padding: 0 }} onClick={() => {
                                        setEditingRow(row?.original);
                                        table.setEditingRow(row);
                                    }}>
                                        <Edit sx={{ height: '16px', width: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip sx={{ padding: 0 }} title="Delete">
                                    <IconButton color="error" onClick={() => setOpenDeletemodal({ flag: true, data: row?.original })}>
                                        <Delete sx={{ height: '16px', width: '16px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    />
                ) : (
                    <Loader />
                )}
            </div>
            <ConfirmationModal
                open={openDeletemodal?.flag}
                onClose={() => setOpenDeletemodal({ flag: false, data: null })}
                header={"Delete Document?"}
                title={
                    <>Are you sure you want to delete <strong>"{openDeletemodal?.data?.first_name}"</strong> employee?</>
                }
                action={() => deleteConfirmation(openDeletemodal?.data?.id)}
            />
            <ToastBar open={toastMess} onClose={setToastMess} />
            <ImagePreviewPopup fileType={fileType} isOpen={isOpen} selectedImage={selectedImage} closePreview={closePreview} />
        </React.Fragment>
    );
});

export default Employee;