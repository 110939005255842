import React, { useState, useEffect } from 'react';
import { COLOR } from '../common/constant';

const FilterComponent = ({ buttons, onSelectionChange, title, clearFilters, minWidth }) => {
    const [selectedButtons, setSelectedButtons] = useState([]);

    const handleButtonClick = (buttonId) => {
        let updatedSelectedButtons;
        if (selectedButtons.includes(buttonId)) {
            updatedSelectedButtons = selectedButtons.filter((id) => id !== buttonId);
        } else {
            updatedSelectedButtons = [...selectedButtons, buttonId];
        }

        if (buttonId !== 'all' && selectedButtons.includes('all')) {
            updatedSelectedButtons = updatedSelectedButtons.filter((id) => id !== 'all');
        }

        if (JSON.stringify(updatedSelectedButtons) !== JSON.stringify(selectedButtons)) {
            setSelectedButtons(updatedSelectedButtons);
        }
    };

    const handleAllClick = () => {
        if (selectedButtons.length === buttons.length) {
            setSelectedButtons([]);
        } else {
            const allButtonIds = buttons.map((button) => button.id);
            setSelectedButtons(allButtonIds);
        }
    };

    const isButtonSelected = (buttonId) => selectedButtons.includes(buttonId);

    const isAllSelected = selectedButtons.length === buttons.length;

    // Effect to handle clear filters from parent component
    useEffect(() => {
        if (clearFilters) {
            setSelectedButtons([]); // Clear all selected buttons
        }
    }, [clearFilters]);

    useEffect(() => {
        onSelectionChange(selectedButtons);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedButtons]);

    return (
        <div className="d_flex-align" style={{ marginRight: "10px" }}>
            <div className="filter-container">
                <p className='filter_title'>{title ?? ""}</p>
                <button style={{ minWidth: minWidth ? minWidth : "120px" }}
                    className={`filter-button ${isAllSelected ? 'selected' : ''}`}
                    onClick={handleAllClick}
                >
                    All
                </button>
                {buttons.map((button) => (
                    <button style={{ minWidth: minWidth ? minWidth : "120px" }}
                        key={button.id}
                        className={`filter-button ${isButtonSelected(button.id) ? 'selected' : ''}`}
                        onClick={() => handleButtonClick(button.id)}
                    >
                        {button.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default FilterComponent;
