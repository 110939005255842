import * as MuiIcons from "@mui/icons-material";
import { Logout, Refresh } from "@mui/icons-material";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { AppBar, Box, Button, debounce, ListItemIcon, Toolbar, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { setIpAddress, setMainMenu } from "../action";
import { COLOR } from "../common/constant";
import { usePostData } from "../common/function/api";
import { getLocalStorage } from "../common/function/common";
import { fetchIPAddress } from "../common/function/fetchIPAddress";
import ToastBar from "../components/ToastBar";

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchDataAPI = usePostData(`/master_fill`);
    const [openSubMenuId, setOpenSubMenuId] = useState(null);
    const [toastMess, setToastMess] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [subMenuPosition, setSubMenuPosition] = useState(null);
    const [isHoveringMainMenu, setIsHoveringMainMenu] = useState(false);
    const [isHoveringSubMenu, setIsHoveringSubMenu] = useState(false);

    const buttonRefs = useRef({});

    const mainMenu = JSON.parse(localStorage.getItem("mainMenu")) || null;
    const userData = getLocalStorage("userAuth");

    const fetchData = async () => {
        try {
            const responseData = await fetchDataAPI.mutateAsync({
                mast_name: "menu"
            });

            if (responseData) {
                if (mainMenu) {
                    setToastMess("Menu Synced Successfully!!");
                }
                localStorage.setItem('mainMenu', JSON.stringify(responseData));
                dispatch(setMainMenu(responseData));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (!mainMenu || mainMenu?.length === 0) fetchData();
    }, [mainMenu]);

    useEffect(() => {
        const getIPAddress = async () => {
            const ip = await fetchIPAddress();
            dispatch(setIpAddress(ip));
        };
        getIPAddress();
    }, []);

    let arr = mainMenu?.filter((item) => !mainMenu?.some((i) => i.menu_id === item.menu_parent_id)).map((e, index) => e);

    const getIconComponent = (iconName, color) => {
        const IconComponent = MuiIcons[iconName];
        return IconComponent ? <IconComponent sx={{ color: color ? color : COLOR.white }} /> : <MuiIcons.Help sx={{ color: COLOR.white }} />;
    };

    const handle = {
        openMenu: debounce((e) => {
            setOpenSubMenuId((prevMenuId) => (prevMenuId === e.menu_id ? null : e.menu_id));
            if (e?.menu_path) {
                navigate(`${e?.menu_path}`);
            }
        }, 100),
        openSubMenu: debounce((e) => {
            navigate(`${e?.menu_path}`);
            setOpenSubMenuId(null);
        }, 100),
        handleMenu: (event) => {
            setAnchorEl(event.currentTarget);
        },
        logOut: () => {
            window.location.reload();
            setAnchorEl(null);
            localStorage.clear();
        },
        handleClose: () => {
            setAnchorEl(null);
        },
        refreshMenu: () => {
            fetchData();
        }
    };

    const handleHoverMenu = (menuId) => {
        const buttonRef = buttonRefs.current[menuId];
        if (buttonRef) {
            const rect = buttonRef.getBoundingClientRect();
            setSubMenuPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + rect.width / 2
            });
        }
        setIsHoveringMainMenu(true);
        setOpenSubMenuId(menuId);
    };

    const handleHoverLeaveMenu = () => {
        if (!isHoveringSubMenu) {
            setOpenSubMenuId(null);
            setSubMenuPosition(null);
        }
        setIsHoveringMainMenu(false);
    };

    const handleHoverSubmenuEnter = () => {
        setIsHoveringSubMenu(true);
    };

    const handleHoverSubmenuLeave = () => {
        setIsHoveringSubMenu(false);
        if (!isHoveringMainMenu) {
            setOpenSubMenuId(null);
            setSubMenuPosition(null);
        }
    };

    return (
        <React.Fragment>
            <AppBar position="static" sx={{ boxShadow: "none", background: 'linear-gradient(360deg, #2476ca 0%, #174ea6 100%)' }}>
                <div className="d_flex-align d_content-between" style={{ margin: "0 10px", overflowX: 'auto', position: "relative" }}>
                    <Toolbar sx={{ padding: "0 !important", minHeight: "40px !important" }}>
                        <img src="/images/dholakia-logo.png" height="45px" style={{ filter: "brightness(0) invert(1)" }} />
                        {arr?.map((item, i) => {
                            const hasSubMenu = mainMenu.some((subItem) => subItem.menu_parent_id === item.menu_id && subItem.is_active);

                            return item?.is_active && (
                                <IconButton
                                    key={item.menu_id}
                                    ref={(el) => {
                                        if (!buttonRefs.current[item.menu_id]) {
                                            buttonRefs.current[item.menu_id] = el;
                                        }
                                    }}
                                    onMouseEnter={() => handleHoverMenu(item.menu_id)}
                                    onMouseLeave={handleHoverLeaveMenu}
                                    onClick={() => {
                                        const hasSubMenu = mainMenu.some((subItem) => subItem.menu_parent_id === item.menu_id && subItem.is_active);
                                        if (!hasSubMenu && item.menu_path) {
                                            navigate(item.menu_path);
                                        }
                                    }}
                                    size="medium"
                                    sx={{
                                        '&:hover': {
                                            borderRadius: 1,
                                            backgroundColor: "rgba(0, 0, 0, 0.15)"
                                        },
                                        alignItems: "center",
                                        color: "white",
                                        transition: "all 0.2s ease-in-out"
                                    }}
                                >
                                    {getIconComponent(item.menu_image_name)}
                                    <p style={{
                                        color: "white",
                                        fontSize: "14px",
                                        lineHeight: "16px",
                                        paddingTop: "1px",
                                        paddingLeft: "5px"
                                    }}>
                                        {item.menu_short_name}
                                    </p>
                                </IconButton>
                            );
                        })}
                    </Toolbar>
                    {userData && (
                        <div>
                            <div className="d_flex-align">
                                <Tooltip title="Refresh Menu">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <IconButton onClick={handle.refreshMenu} size="small" sx={{ ml: 2, color: "white" }}>
                                            <Refresh sx={{ color: "white" }} />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                                <Tooltip title="Account settings">
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <IconButton
                                            onClick={handle.handleMenu}
                                            size="small"
                                            aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                        >
                                            <AccountCircle sx={{ color: "white", transition: "all 0.2s ease-in-out" }} />
                                        </IconButton>
                                        {userData?.email && <h5 style={{ fontWeight: "normal", color: "white", margin: "0 8px" }}>{userData?.email}</h5>}
                                    </Box>
                                </Tooltip>
                            </div>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={Boolean(anchorEl)}
                                onClose={handle.handleClose}
                                sx={{
                                    '& .MuiPaper-root': {
                                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
                                        mt: 1.5,
                                    }
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={handle.logOut}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </div>
                {openSubMenuId && subMenuPosition && (
                    <Box
                        onMouseEnter={handleHoverSubmenuEnter}
                        onMouseLeave={handleHoverSubmenuLeave}
                        sx={{
                            position: "absolute",
                            top: subMenuPosition.top,
                            left: subMenuPosition.left,
                            transform: 'translateX(-50%)',
                            width: "auto",
                            display: 'flex',
                            justifyContent: 'flex-start',
                            backgroundColor: COLOR.lightGray,
                            px: 1,
                            borderRadius: "5px",
                            boxShadow: '0px 2px 7px 3px rgba(0, 0, 0, 0.2)',
                            borderBottom: "1px solid #ccc",
                            zIndex: 111
                        }}
                    >
                        {mainMenu
                            .filter((item) => item.menu_parent_id === openSubMenuId && item.is_active)
                            .map((item, i) => (
                                <Button
                                    key={i}
                                    onClick={() => handle.openSubMenu(item)}
                                    sx={{
                                        position: 'relative',
                                        color: COLOR.black,
                                        fontWeight: 500,
                                        transition: 'background-color 0.3s ease',
                                        borderRadius: '4px',
                                        padding: '8px 12px 2px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        minWidth: "85px",
                                        margin: '2px',
                                        '&:hover': {
                                            backgroundColor: '#e0e0e0',
                                        },
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            right: '-2px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            width: '1px',
                                            height: '40px',
                                            backgroundColor: "#ccc",
                                        },
                                        '&:last-child::after': {
                                            content: 'none'
                                        },
                                    }}
                                >
                                    {getIconComponent(item?.menu_image_name, "#000000")}
                                    <span>{item.menu_short_name}</span>
                                </Button>
                            ))}
                    </Box>
                )}
            </AppBar>
            <div className="d_layout">
                {children || <Outlet />}
            </div>
            <ToastBar open={toastMess} onClose={setToastMess} />
        </React.Fragment>
    );
};

export default Layout;
