import { Clear, Save, Search } from "@mui/icons-material";
import { Autocomplete, Box, Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import ToastBar from "../components/ToastBar";
import VirtualizedTableData from "../components/VirtualizedTableData";

const FormPermission = React.memo(({ form_name, type_id }) => {
    const boxRef = useRef(null);
    const masterFillAPI = usePostData(`/master_fill`);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const formPermissionView = usePostData(`/form_permission_view`);
    const formPermissionSave = usePostData(`/form_permission_save`);
    const [boxHeight, setBoxHeight] = useState(0);
    const [visibleColumn, setVisibleColumn] = useState({});
    const [columnResponse, setColumnResponse] = useState(null);
    const [rows, setRows] = useState([]);
    const [formData, setFormData] = useState({});
    const [options, setOptions] = useState({
        role_name: [],
    });
    const [loading, setLoading] = useState(false);
    const [toastMess, setToastMess] = useState(null);
    useEffect(() => {
        // Get the height of the div after it is rendered
        if (boxRef.current) {
            const height = boxRef.current.clientHeight;
            setBoxHeight(height + 85);
        }
    }, []);

    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_type": mastType,
                "mast_name": mastName
            });

            return { [key]: responseData };
        } catch (error) {
            console.error('Error:', error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [roleName] = await Promise.all([
                fetchData("select", "role", "role_name"),
            ]);
            setOptions({ ...roleName });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchRows = async (resetFields = false) => {
        setLoading(true);
        if (resetFields) {
            setFormData({ role: null });
        }
        try {
            const responseData = await formPermissionView.mutateAsync({
                "permission_role_id": formData?.role?.value,
            });
            const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({
                id: index + 1,
                ...row
            })) : [];

            setRows(rowsData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync({
                type_id: type_id,
                form_name: form_name,
                view_name: "form_permission"
            });
            const alignmentMap = {
                3: 'right',
                2: 'center',
                1: 'left'
            };
            const columns = responseData?.map((col) => ({
                accessorKey: col.column_name,
                header: col.column_header,
                filterVariant: 'autocomplete',
                size: col?.column_width,
                columnType: col?.column_type,
                textAlign: alignmentMap[col?.column_align],
                filterFn: 'includesString',
                is_visible: col?.is_visible,
                is_visible_form: col?.is_visible_form
            }));
            setColumnResponse(columns);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchColumn();
        fetchAllData();
    }, []);

    const handleCheckboxChange = (rowIndex, checkboxName, newValue) => {
        const updatedTableData = [...rows];
        const currentRow = updatedTableData[rowIndex];
        const updatedRow = {
            ...currentRow,
            [checkboxName]: newValue,
        };
        updatedTableData[rowIndex] = updatedRow;
        setRows(updatedTableData);
    };

    const saveData = async (resetFields = false) => {
        setLoading(true);
        if (resetFields) {
            setFormData({ role: null });
        }
        try {
            const responseData = await formPermissionSave.mutateAsync({
                "permission_role_id": formData?.role?.value,
                data_list: rows
            });
            const parseData = JSON?.parse(responseData?.[0]?.request_body_data) ?? [];
            if (responseData) {
                setRows(parseData?.data_list);
                setToastMess("Data Updated Successfully!!");
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
            if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
                acc[column.accessorKey] = column.is_visible ?? true;
            }
            return acc;
        }, {}))
    }, [columnResponse])

    const handleAutocompleteChange = (event, newValue) => {
        setFormData({ ...formData, role: newValue });
    };

    return (
        <React.Fragment>
            <Box ref={boxRef} sx={{ display: "flex", justifyContent: "space-between", padding: "15px 15px 0 15px" }}>
                <div className="d_row" style={{ width: "100%" }}>
                    <div className="d_col-2">
                        <FormControl fullWidth>
                            <Autocomplete
                                value={formData?.role || null}  // Ensuring the value is null if role is undefined or null
                                onChange={handleAutocompleteChange}
                                options={options?.role_name || []}
                                getOptionLabel={(option) => { return option.label ? option.label : "Unknown Label"; }}
                                size="small"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Role"}
                                        fullWidth
                                    />
                                )}
                                filterOptions={(options, state) => {
                                    const inputValue = state.inputValue.toLowerCase();
                                    return options.filter(option => option?.label?.toLowerCase().includes(inputValue));
                                }}
                                isOptionEqualToValue={(option, value) => option?.value === value}
                            />
                        </FormControl>
                    </div>
                </div>
                <Box>
                    <Box display="flex" gap={1} marginLeft={2} marginBottom="10px">
                        <Button variant="contained" onClick={() => fetchRows()} startIcon={<Search />} sx={{ ...(loading && { color: "white !important" }) }}>{loading ? "Finding..." : "Find"}</Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                const newFormData = { ...formData };
                                delete newFormData.role;
                                setFormData(newFormData);
                                setRows([]); // Optionally clear the rows too
                                setVisibleColumn({}); // Optionally clear the visible columns too
                            }}
                            startIcon={<Clear />}
                        >
                            Clear
                        </Button>
                        {!!rows?.length && <Button variant="contained" onClick={() => saveData()} startIcon={<Save />} sx={{ ...(loading && { color: "white !important" }) }}>Save</Button>}
                    </Box>
                </Box>
            </Box>
            <Box>
                <div style={{ height: `calc(100vh - ${boxHeight}px)`, width: "100%" }}>
                    {(columnResponse && visibleColumn !== null) &&
                        <VirtualizedTableData
                            style={{ margin: 0 }}
                            className="left-0"
                            height={`calc(100vh - ${boxHeight}px)`}
                            data={rows} visibleColumn={visibleColumn}
                            loading={!loading && !viewColumnsFillAPI?.isPending}
                            columnResponse={columnResponse}
                            rowCount={0}
                            ischeckBoxEdit={true}
                            handleCheckboxChange={handleCheckboxChange}
                        />}
                </div>
            </Box>
            <ToastBar open={toastMess} onClose={setToastMess} />
        </React.Fragment>
    );
});

export default FormPermission;
