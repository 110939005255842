import { Alert, Snackbar } from "@mui/material";
import React from "react";

const ToastBar = ({ open, onClose, severity = "success" }) => {
  let opens = open ? true : false
  return (
    opens && <Snackbar
      open={opens} onClose={(reason) => { if (reason === 'clickaway') return; onClose("") }} autoHideDuration={3000} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      <Alert onClose={(reason) => { if (reason === 'clickaway') return; onClose("") }} severity={severity} sx={{ width: '100%' }}>
        {open || ""}
      </Alert>
    </Snackbar>
  )
};

export default ToastBar;
