import Autocomplete from '@mui/material/Autocomplete';
import Grid2 from '@mui/material/Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import * as React from 'react';

const CountryCodeWithPhoneInput = ({
    names,
    selectedCode,
    phoneNumber,
    handleChange,
    columnKey,
    columnType,
    headerName,
    required
}) => {
    // Custom Popper to control the dropdown width
    const StyledPopper = styled(Popper)({
        width: '250px !important', // Set desired dropdown width
    });

    return (
        <Grid2 container spacing={2}>
            {/* Mobile Number Input with Country Code Prefix */}
            <Grid2 item xs={12} sx={{ width: '100%' }}>
                <TextField
                    required={required}
                    label={headerName}
                    value={phoneNumber}
                    onChange={(e) => handleChange({ target: { value: e.target.value } }, columnKey, columnType)}
                    variant="outlined"
                    placeholder="Enter mobile number"
                    size="small"
                    className='d_country_code'
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Autocomplete
                                    style={{ minWidth: '40px', width: '100%' }}
                                    forcePopupIcon={false}
                                    options={names}
                                    value={selectedCode ?? { value: '', label: '' }}
                                    disableClearable
                                    onChange={(event, newValue) => handleChange({ target: { value: newValue } }, columnKey, 'select')}
                                    // getOptionLabel={(option) => `${option.label?.split(":")[0].trim()}`}
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {option.label ? option.label : "Unknown"}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} size="small" className='d_country_code_inner' />
                                    )}
                                    fullWidth
                                    size="small"
                                    PopperComponent={StyledPopper} // Use custom Popper
                                />

                            </InputAdornment>
                        ),
                    }}
                />
            </Grid2>
        </Grid2>
    );
}
export default CountryCodeWithPhoneInput;
