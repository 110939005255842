import { Button } from "@mui/material";
import React from "react"
import DModal from "./DModal";
import Magnifier from "react-magnifier";

const CertificateModal = ({ src, onClose }) => {
    return (
        <React.Fragment>
            <DModal
                open={src}
                onClose={onClose}
                backDrop
                DialogTitle={"Certificate"}
                DialogContent={
                    <React.Fragment>
                        <Magnifier src={src} width={"auto"} zoomFactor={1} />
                    </React.Fragment>
                }
                DialogActions={
                    <Button
                        onClick={onClose}
                        variant="contained"
                        color="secondary"
                        sx={{ background: "linear-gradient(90deg, #2476ca 0%, #174ea6 100%)" }}
                    >
                        Close
                    </Button>
                }
            />
        </React.Fragment>
    )
};

export default CertificateModal;
