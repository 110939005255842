import { Box, Button, Grid2, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { COLOR } from '../common/constant';

function NumberPicker({ onChange, clearInput }) {
    const [inputValueFrom, setInputValueFrom] = useState('00.00');
    const [inputValueTo, setInputValueTo] = useState('00.00');
    const [selectedDigits, setSelectedDigits] = useState([0, 0, 0, 0]);
    const [activeKey, setActiveKey] = useState('from_carat');
    const [hoveredKey, setHoveredKey] = useState(null);

    // Clear input values when the clearInput prop changes
    useEffect(() => {
        if (clearInput) {
            setInputValueFrom('00.00');
            setInputValueTo('00.00');
            setSelectedDigits([0, 0, 0, 0]);
            onChange('from_carat', '00.00');
            onChange('to_carat', '00.00');
        }
    }, [clearInput, onChange]);

    const handleNumberClick = (lineIndex, number) => {
        const newSelectedDigits = [...selectedDigits];
        newSelectedDigits[lineIndex] = number;
        setSelectedDigits(newSelectedDigits);

        const combinedValue = `${newSelectedDigits[0]}${newSelectedDigits[1]}.${newSelectedDigits[2]}${newSelectedDigits[3]}`;

        if (activeKey === 'from_carat') {
            setInputValueFrom(combinedValue);
            onChange('from_carat', combinedValue);
        } else {
            setInputValueTo(combinedValue);
            onChange('to_carat', combinedValue);
        }
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,2}(\.\d{0,2})?$/.test(value)) {
            if (activeKey === 'from_carat') {
                setInputValueFrom(value);
            } else {
                setInputValueTo(value);
            }
            onChange(activeKey, value);
        }
    };

    const handleMouseEnter = (key) => {
        setActiveKey(key);
        const digits = key === 'from_carat' ? inputValueFrom : inputValueTo;
        const parsedDigits = digits.split('.').join('').padEnd(4, '0').split('').map(Number);
        setSelectedDigits(parsedDigits);
        setHoveredKey(key);
    };

    const handleMouseLeave = () => {
        setHoveredKey(null);
    };

    const buttonStyles = useMemo(() => (lineIndex, number) => ({
        height: '30px',
        width: '30px',
        minWidth: '30px',
        maxWidth: '30px',
        padding: '10px',
        backgroundColor: selectedDigits[lineIndex] === number ? '#2476ca3a' : 'transparent',
        color: COLOR.primary,
        borderColor: selectedDigits[lineIndex] === number ? COLOR.primary : '#1f76ce80',
    }), [selectedDigits]);

    useEffect(() => {
        const updateSelectedDigits = (inputValue) => {
            const digits = inputValue.split('.').join('').padEnd(4, '0').split('').map(Number);
            setSelectedDigits(digits);
        };
        updateSelectedDigits(activeKey === 'from_carat' ? inputValueFrom : inputValueTo);
    }, [inputValueFrom, inputValueTo, activeKey]);

    const dropdownStyle = {
        position: 'absolute',
        top: '30px',
        left: '60px',
        backgroundColor: 'white',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        zIndex: 11,
    };

    return (
        <Box display="flex" alignItems="center">
            {['from_carat', 'to_carat'].map((key) => (
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ background: 'white', border: '1px solid #ccc' }}
                    onMouseEnter={() => handleMouseEnter(key)}
                    onMouseLeave={handleMouseLeave}
                    style={{ position: 'relative' }}
                >
                    <p className="filter_title" style={{ border: 'none' }}>{key === 'from_carat' ? 'From' : 'To'}</p>
                    <TextField
                        label=""
                        size="small"
                        value={key === 'from_carat' ? inputValueFrom : inputValueTo}
                        onChange={handleInputChange}
                        variant="outlined"
                        inputProps={{ maxLength: 5 }}
                        placeholder="00.00"
                        InputLabelProps={{ shrink: true }}
                        sx={{
                            height: 26,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': { border: 'none' },
                                '&:hover fieldset': { border: 'none' },
                                '&.Mui-focused fieldset': { border: 'none' },
                            },
                            '& .MuiInputBase-root': { height: 26, fontSize: '14px' },
                        }}
                    />
                    {hoveredKey === key && (
                        <div style={dropdownStyle}>
                            <Grid2 container spacing={0.2} justifyContent="center" flexWrap="nowrap">
                                {/* First two columns */}
                                {Array.from({ length: 2 }).map((_, lineIndex) => (
                                    <Grid2 container direction="column" spacing={0.2} alignItems="center" key={lineIndex}>
                                        {Array.from({ length: 10 }).map((_, number) => (
                                            <Button
                                                key={number}
                                                variant="outlined"
                                                onClick={() => handleNumberClick(lineIndex, number)}
                                                style={buttonStyles(lineIndex, number)}
                                            >
                                                {number}
                                            </Button>
                                        ))}
                                    </Grid2>
                                ))}

                                {/* Dot in each row */}
                                {Array.from({ length: 1 }).map((_, lineIndex) => (
                                    <Grid2 container direction="column" spacing={0.2} alignItems="center" key={lineIndex}>
                                        {Array.from({ length: 10 }).map((_, number) => (
                                            <Box
                                                key={number}
                                                sx={{
                                                    height: '30px',
                                                    width: '30px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                .
                                            </Box>
                                        ))}
                                    </Grid2>
                                ))}

                                {/* Last two columns */}
                                {Array.from({ length: 2 }).map((_, lineIndex) => (
                                    <Grid2 container direction="column" spacing={0.2} alignItems="center" key={lineIndex}>
                                        {Array.from({ length: 10 }).map((_, number) => (
                                            <Button
                                                key={number}
                                                variant="outlined"
                                                onClick={() => handleNumberClick(lineIndex + 2, number)}
                                                style={buttonStyles(lineIndex + 2, number)}
                                            >
                                                {number}
                                            </Button>
                                        ))}
                                    </Grid2>
                                ))}
                            </Grid2>
                        </div>
                    )}

                </Box>
            ))}
        </Box>
    );
}

export default NumberPicker;
