import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, InputLabel } from '@mui/material';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const DCustomDatePicker = ({
    headerName,
    required = false,
    selectedDate,
    onChange,
    columnKey,
    disabled = false
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}>
                    <InputLabel className="d_date-label" style={{ marginBottom: 8 }}>
                        {headerName} {required ? <span style={{ color: 'red' }}>*</span> : ""}
                    </InputLabel>
                    <DatePicker
                        margin="normal"
                        format="DD-MM-YYYY"
                        value={selectedDate || null}
                        onChange={(date) => onChange(date, columnKey)}
                        disabled={disabled}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                placeholder="DD-MM-YYYY"
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: 30,
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '6px 8px',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '18px',
                                    },
                                }}
                            />
                        )}
                        inputFormat="DD-MM-YYYY"
                    />
                </div>
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default DCustomDatePicker;
