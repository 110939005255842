import { Clear, LocalOffer, ReceiptLong, Search } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Switch } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePostData } from "../common/function/api";
import FilterComponent from "../components/filter";
import NumberPicker from "../components/NumberPicker";
import VirtualizedTableData from "../components/VirtualizedTableData";
import MemoView from "./memo-view";
import SaleView from "./sale-view";
import Loader from "../components/loader";

const Inventory = React.memo(({ type_id, form_name }) => {
    const boxRef = useRef(null);
    const viewColumnsFillAPI = usePostData(`/view_columns_fill`);
    const inventoryViewAPI = usePostData(`/inventory_view`);
    const masterFillAPI = usePostData(`/master_fill`);
    const [columnResponse, setColumnResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visibleColumn, setVisibleColumn] = useState({});
    const [filterData, setFilterData] = useState({
        shape_name: [],
        color_name: [],
        clarity_name: [],
        cut_name: [],
        pol_name: [],
        sym_name: [],
        lab_name: [],
        color_shade_name: [],
        bt_name: [],
        bc_name: [],
        wt_name: [],
        wc_name: [],
        to_name: [],
        po_name: [],
        co_name: [],
    });
    const [rows, setRows] = useState([]);
    const [advanceOpt, setAdvanceOpt] = useState(false);
    const [clearFilters, setClearFilters] = useState(false);
    const [selectedIds, setSelectedIds] = useState({
        shapeFilter: [],
        colorFilter: [],
        clarityFilter: [],
        cutFilter: [],
        polFilter: [],
        symFilter: [],
        labFilter: [],
        color_shadeFilter: [],
        milkyFilter: [],
        btFilter: [],
        bcFilter: [],
        wtFilter: [],
        wcFilter: [],
        toFilter: [],
        poFilter: [],
        coFilter: [],
        is_nobgm: false
    });
    const [values, setValues] = useState({ from_carat: '00.00', from_carat: '00.00' });
    const [selectedDiamondIds, setSelectedDiamondIds] = useState([]);
    const [openMemoForm, setOpenMemoForm] = useState(null);
    const [openSaleForm, setOpenSaleForm] = useState(null);
    const [clearInput, setClearInput] = useState(false);
    const [boxHeight, setBoxHeight] = useState(0);
    useEffect(() => {
        const handleResize = () => {
            if (boxRef.current) {
                const height = boxRef.current.clientHeight;
                setBoxHeight(height + 124);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [rows, advanceOpt]);

    const handleSelectionChange = useCallback((filterKey, selectedButtons) => {
        setSelectedIds((prevState) => ({
            ...prevState,
            [filterKey]: selectedButtons,
        }));
    }, []);

    const handle = {
        viewMemo: (d_id) => {
            setOpenMemoForm(d_id)
        },
        viewSale: (d_id) => {
            setOpenSaleForm(d_id)
        },
        serachFilter: () => {
            fetchData()
        },
        clearFilters: () => {
            setClearFilters(true); // Trigger clearing in child components

            // Reset clearFilters after filters are cleared
            setTimeout(() => {
                setClearFilters(false);
                setRows([]);
                setClearInput(prevState => !prevState);
            }, 0); // Reset after clearing to avoid re-render loops
        },
        selectedRowsChange: (diamondIds) => {
            setSelectedDiamondIds(diamondIds);
        }
    }

    const memoizedOnChange = useCallback((key, value) => {
        setValues((prev) => ({ ...prev, [key]: value }));
    }, []);

    const fetchMasterData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_type": mastType,
                "mast_name": mastName
            });
            return { [key]: responseData };
        } catch (error) {
            console.error('Error:', error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [shape, color, clarity, cut, pol, sym, lab, color_shade, milky, bt, bc, wt, wc, to, po, co] = await Promise.all([
                fetchMasterData("select", "shape", "shape_name"),
                fetchMasterData("select", "color", "color_name"),
                fetchMasterData("select", "clarity", "clarity_name"),
                fetchMasterData("select", "cut", "cut_name"),
                fetchMasterData("select", "pol", "pol_name"),
                fetchMasterData("select", "sym", "sym_name"),
                fetchMasterData("select", "lab", "lab_name"),
                fetchMasterData("select", "color_shade", "color_shade_name"),
                fetchMasterData("select", "milky", "milky_name"),
                fetchMasterData("select", "bt", "bt_name"),
                fetchMasterData("select", "bc", "bc_name"),
                fetchMasterData("select", "wt", "wt_name"),
                fetchMasterData("select", "wc", "wc_name"),
                fetchMasterData("select", "to", "to_name"),
                fetchMasterData("select", "po", "po_name"),
                fetchMasterData("select", "co", "co_name"),
            ]);

            setFilterData({
                ...shape, ...color, ...clarity, ...cut, ...pol, ...sym, ...lab, ...color_shade, ...milky, ...bt, ...bc, ...wt, ...wc, ...to, ...po, ...co,
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchFieldData = {
        type_id: type_id,
        form_name: form_name,
    };

    const fetchColumn = async () => {
        try {
            const responseData = await viewColumnsFillAPI.mutateAsync(fetchFieldData);
            const alignmentMap = {
                3: 'right',
                2: 'center',
                1: 'left'
            };
            const columns = responseData?.map((col) => ({
                accessorKey: col.column_name,
                header: col.column_header,
                filterVariant: 'autocomplete',
                size: col?.column_width,
                columnType: col?.column_type,
                textAlign: alignmentMap[col?.column_align],
                filterFn: 'includesString',
                is_visible: col?.is_visible,
                is_visible_form: col?.is_visible_form,
                groupName: col?.group_name,
                cell: (info) => {
                    const value = info.getValue();
                    if (typeof value === "number" && col?.column_format !== null) {
                        return value.toFixed(col?.column_format);
                    }
                    return value;
                }
            }));

            setColumnResponse(columns);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const responseData = await inventoryViewAPI.mutateAsync({
                shape_code: selectedIds?.shapeFilter?.join(","),
                color_code: selectedIds?.colorFilter?.join(","),
                clarity_code: selectedIds?.clarityFilter?.join(","),
                cut_code: selectedIds?.cutFilter?.join(","),
                pol_code: selectedIds?.polFilter?.join(","),
                sym_code: selectedIds?.symFilter?.join(","),
                lab_code: selectedIds?.labFilter?.join(","),
                color_shade_code: selectedIds?.color_shadeFilter?.join(","),
                bt_code: selectedIds?.btFilter?.join(","),
                bc_code: selectedIds?.bcFilter?.join(","),
                wt_code: selectedIds?.wtFilter?.join(","),
                wc_code: selectedIds?.wcFilter?.join(","),
                to_code: selectedIds?.toFilter?.join(","),
                po_code: selectedIds?.poFilter?.join(","),
                co_code: selectedIds?.coFilter?.join(","),
                is_nobgm: selectedIds?.is_nobgm,
                ...(values?.to_carat && { from_carat: Number(values?.from_carat) }),
                ...(values?.to_carat && { to_carat: Number(values?.to_carat) }),
            });

            const rowsData = responseData?.length !== 0 ? responseData.map((row, index) => ({
                id: index + 1,
                ...row
            })) : [];

            setRows(rowsData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchColumn();
        fetchAllData();
    }, [type_id, form_name]);

    useEffect(() => {
        setVisibleColumn(columnResponse && columnResponse?.reduce((acc, column) => {
            if (column.accessorKey && column.accessorKey !== 'mrt-row-actions') { // Exclude the action column
                acc[column.accessorKey] = column.is_visible ?? true;
            }
            return acc;
        }, {}))
    }, [columnResponse])

    const sumRow = {};
    let selectedRows = rows && rows?.filter(diamond => new Set(selectedDiamondIds).has(diamond["diamond_id"]));
    const rowsToSum = selectedRows?.length ? selectedRows : rows;
    const numberColumns = ['diamond_id', 'srt_amount', 'srt_carat', 'srt_cost', 'carat', 'rap', 'rap_amount', 'disc', 'rate', 'amount']

    const amount = rowsToSum.reduce((acc, row) => acc + Number(row['amount'] || 0), 0);
    const caratSum = rowsToSum.reduce((acc, row) => acc + Number(row['carat'] || 0), 0);
    const rapAmountSum = rowsToSum.reduce((acc, row) => acc + Number(row['rap_amount'] || 0), 0);

    const rate = caratSum ? (amount / caratSum).toFixed(2) : 0;
    const rap = caratSum ? (rapAmountSum / caratSum).toFixed(2) : 0;
    const rateDisc = rapAmountSum ? (((amount / rapAmountSum) * 100) - 100).toFixed(2) : 0;

    sumRow['diamond_id'] = rowsToSum?.length;
    sumRow['carat'] = caratSum.toFixed(2);
    sumRow['rap'] = rap;
    sumRow['rap_amount'] = rapAmountSum.toFixed(2);
    sumRow['disc'] = rateDisc;
    sumRow['amount'] = amount.toFixed(2);
    sumRow['rate'] = rate;

    return (
        <React.Fragment>
            {!masterFillAPI?.isSuccess ? <Loader /> : <Box sx={{ background: "#f9f9f9", height: "100%", flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flex: "0 0 auto" }}>
                    <Box sx={{ margin: "10px 0 3px 10px" }} ref={boxRef}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Box sx={{ width: "20%" }}>
                                <NumberPicker onChange={memoizedOnChange} clearInput={clearInput} />
                                {/* <TextboxWithPopup/> */}
                            </Box>
                            <Box sx={{ width: "80%" }}>
                                {Array.isArray(filterData.shape_name) && filterData.shape_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="Shape" minWidth="70px"
                                        buttons={filterData.shape_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('shapeFilter', selectedButtons)}
                                    />
                                )}
                            </Box>
                        </Box>
                        {Array.isArray(filterData.color_name) && filterData.color_name.length > 0 && (
                            <FilterComponent clearFilters={clearFilters}
                                title="Color" minWidth="40px"
                                buttons={filterData.color_name}
                                onSelectionChange={(selectedButtons) => handleSelectionChange('colorFilter', selectedButtons)}
                            />
                        )}
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                            {Array.isArray(filterData.clarity_name) && filterData.clarity_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Clarity" minWidth="40px"
                                    buttons={filterData.clarity_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('clarityFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.cut_name) && filterData.cut_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Cut" minWidth="40px"
                                    buttons={filterData.cut_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('cutFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.pol_name) && filterData.pol_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Pol" minWidth="40px"
                                    buttons={filterData.pol_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('polFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.sym_name) && filterData.sym_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Sym" minWidth="40px"
                                    buttons={filterData.sym_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('symFilter', selectedButtons)}
                                />
                            )}
                            {Array.isArray(filterData.lab_name) && filterData.lab_name.length > 0 && (
                                <FilterComponent clearFilters={clearFilters}
                                    title="Lab" minWidth="40px"
                                    buttons={filterData.lab_name}
                                    onSelectionChange={(selectedButtons) => handleSelectionChange('labFilter', selectedButtons)}
                                />
                            )}
                            <Box>
                                {Array.isArray(filterData.color_shade_name) && filterData.color_shade_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="Shd" minWidth="40px"
                                        buttons={filterData.color_shade_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('color_shadeFilter', selectedButtons)}
                                    />
                                )}
                            </Box>
                            <FormControlLabel
                                control={<Checkbox size="small" checked={selectedIds?.is_nobgm} color="primary" onChange={() => handleSelectionChange('is_nobgm', !selectedIds?.is_nobgm)} />}
                                label="No BGM"
                            />
                        </Box>
                        <div className={`openAdvance ${advanceOpt ? 'show' : ''}`}>
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {Array.isArray(filterData.bt_name) && filterData.bt_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="bt" minWidth="40px"
                                        buttons={filterData.bt_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('btFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.bc_name) && filterData.bc_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="bc" minWidth="40px"
                                        buttons={filterData.bc_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('bcFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.wt_name) && filterData.wt_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="wt" minWidth="40px"
                                        buttons={filterData.wt_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('wtFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.wc_name) && filterData.wc_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="wc" minWidth="40px"
                                        buttons={filterData.wc_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('wcFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.to_name) && filterData.to_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="to" minWidth="40px"
                                        buttons={filterData.to_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('toFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.po_name) && filterData.po_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="po" minWidth="40px"
                                        buttons={filterData.po_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('poFilter', selectedButtons)}
                                    />
                                )}
                                {Array.isArray(filterData.co_name) && filterData.co_name.length > 0 && (
                                    <FilterComponent clearFilters={clearFilters}
                                        title="co" minWidth="40px"
                                        buttons={filterData.co_name}
                                        onSelectionChange={(selectedButtons) => handleSelectionChange('coFilter', selectedButtons)}
                                    />
                                )}
                            </Box>
                        </div>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", px: 1, gap: 1 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={advanceOpt}
                                    onChange={(e) => setAdvanceOpt(!advanceOpt)}
                                />
                            }
                            label={"Advance"}
                        />
                        <Button
                            variant="contained"
                            onClick={() => handle?.serachFilter()}
                            disabled={loading}
                            startIcon={<Search />}
                            sx={{ ...(loading && { color: "white !important" }) }}
                        >
                            {loading ? "Finding..." : "Find"}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handle?.clearFilters()}
                            startIcon={<Clear />}
                        >
                            Clear
                        </Button>
                        {!!selectedDiamondIds?.length && <Button
                            variant="contained"
                            onClick={() => handle.viewMemo(selectedDiamondIds)}
                            startIcon={<ReceiptLong />}
                        >
                            Memo
                        </Button>}
                        {!!selectedDiamondIds?.length && <Button
                            variant="contained"
                            onClick={() => handle.viewSale(selectedDiamondIds)}
                            startIcon={<LocalOffer />}
                        >
                            Sale
                        </Button>}
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ height: `calc(96vh - ${boxHeight}px)`, width: "100%" }}>
                        {(columnResponse && visibleColumn !== null) &&
                            <VirtualizedTableData
                                height={`calc(96vh - ${boxHeight}px)`}
                                data={rows} rowSelection
                                loading={!loading && !viewColumnsFillAPI?.isPending && !inventoryViewAPI?.isPending}
                                columnResponse={columnResponse}
                                rowCount={rows?.length} visibleColumn={visibleColumn}
                                onSelectedRowsChange={handle?.selectedRowsChange}
                                selectedId="diamond_id"
                                clearSelection={clearFilters}
                                memoSumRow={sumRow}
                                numberColumns={numberColumns}
                                isGallery isSticky
                            />}
                    </div>
                </Box>
            </Box>}
            {openMemoForm && <MemoView open={openMemoForm} onClose={setOpenMemoForm} />}
            {openSaleForm && <SaleView open={openSaleForm} onClose={setOpenSaleForm} />}
        </React.Fragment >
    );
});

export default Inventory;