import { Autocomplete, Button, DialogContent, FormControl, FormControlLabel, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { usePostData } from "../common/function/api";
import DModal from "../components/DModal";
import DTextField from "../components/DTextField";
import CountryCodeWithPhoneInput from "../components/MobileCountrySelect";
import MultipleSelectCheckmarks from "../components/MultipleSelectCheckmarks";

const PartyModal = React.memo(({ formData, handleChange, error, type, modalWidth, columnResponse, createFunction, onClose }) => {

    const masterFillAPI = usePostData(`/master_fill`);
    const [multiSelectData, setMultiSelectData] = useState({
        party_type_name: [],
        group_party_name: [],
        sale_person_name: [],
        classification_name: [],
        business_type_name: [],
        party_rating_name: [],
        isd_code1_name: [],
        member_type: [],
    });

    const fetchData = async (mastType, mastName, key) => {
        try {
            const responseData = await masterFillAPI.mutateAsync({
                "mast_type": mastType,
                "mast_name": mastName
            });

            return { [key]: responseData };
        } catch (error) {
            console.error('Error:', error);
            return { [key]: [] };
        }
    };

    const fetchAllData = async () => {
        try {
            const [salePerson, memberType, groupParty, classification, subPartyType, businessType, partyRating, isdCode] = await Promise.all([
                fetchData("select", "sale_person", "sale_person_name"),
                fetchData("select", "member_type", "member_type_name"),
                fetchData("select", "group_party", "group_party_name"),
                fetchData("select", "party_classification", "classification_name"),
                fetchData("select", "party_type", "party_type_name"),
                fetchData("select", "business_type", "business_type_name"),
                fetchData("select", "party_rating", "party_rating_name"),
                fetchData("select", "isd_code", "isd_code1_name")
            ]);

            setMultiSelectData({
                ...multiSelectData,
                ...memberType,
                ...salePerson,
                ...groupParty,
                ...classification,
                ...subPartyType,
                ...businessType,
                ...partyRating,
                ...isdCode
            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchAllData();
    }, []);

    const isCreate = type;
    return (
        <React.Fragment>
            <DModal
                open={true}
                modalWidth={modalWidth}
                backDrop
                onClose={onClose}
                DialogTitle={isCreate === "create" ? 'Add New' : "Edit"}
                DialogContent={<DialogContent className="d_row" sx={{ padding: "24px 24px 16px 24px !important" }}>
                    {columnResponse?.filter(_ => _?.accessorKey !== "is_party_name_as_per_member_name" && _?.accessorKey !== "actions").map((component, index) => {
                        const columnKey = component?.accessorKey;
                        const columnType = component?.column_type || component?.columnType;
                        const cssClassName = component?.css_class_name;
                        const required = component?.is_required;
                        const headerName = component.header;
                        const isFirstField = index === 1;
                        if (columnKey === 'isd_code2_name' || columnKey === 'isd_code1_name') {
                            return null;
                        }

                        if (columnType === 'contact') {
                            return (
                                <div className={cssClassName}>
                                    <CountryCodeWithPhoneInput
                                        required={required}
                                        names={multiSelectData?.isd_code1_name}
                                        selectedCode={formData[columnKey] || { value: '', label: '' }}
                                        phoneNumber={formData[columnKey]?.number || ''}
                                        handleChange={handleChange}
                                        columnKey={columnKey}
                                        columnType={columnType}
                                        headerName={headerName}
                                    />
                                </div>
                            );
                        }

                        if (columnType === 'select') {
                            let options = [];
                            if (columnKey === 'isd_code2_name') {
                                options = multiSelectData.isd_code1_name;
                            } else {
                                options = multiSelectData[columnKey];
                            }

                            const selectedOption = options && (options.find(option => option.value === formData[columnKey]?.value) || null);

                            return (
                                <div className={cssClassName}>
                                    <FormControl fullWidth key={columnKey}>
                                        <Autocomplete
                                            value={selectedOption || null}
                                            onChange={(event, newValue) => handleChange({ target: { value: newValue } }, columnKey, columnType)}
                                            options={options}
                                            sx={{
                                                '& .MuiAutocomplete-listbox': {
                                                    '& .MuiAutocomplete-option': {
                                                        padding: '0 10px',
                                                    },
                                                },
                                            }}
                                            autoFocus={isFirstField}
                                            getOptionLabel={(option) => { return option.label ? option.label : "Unknown Label"; }}
                                            renderInput={(params) => (
                                                <TextField
                                                    required={required}
                                                    {...params}
                                                    autoFocus={isFirstField}
                                                    label={headerName}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        sx: {
                                                            fontSize: '14px',
                                                            lineHeight: '22px',
                                                        },
                                                    }}
                                                />
                                            )}
                                            size="small"
                                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                        />
                                    </FormControl>
                                </div>
                            );
                        }

                        if (columnType === 'multi-select') {
                            let options = [];
                            if (columnKey === 'party_type_name') {
                                options = multiSelectData.party_type_name;
                            } else {
                                options = multiSelectData[columnKey];
                            }
                            const selectedOption = options && (options?.filter(option => formData[columnKey]?.some(item => item?.value === option?.value)) || []);

                            const handleSelectionChange = (newValue) => {
                                const selectedValues = newValue.map(option => option);
                                handleChange({ target: { value: selectedValues } }, columnKey, columnType);
                            };

                            return (
                                <div className={cssClassName}>
                                    <FormControl fullWidth key={columnKey}>
                                        <MultipleSelectCheckmarks
                                            required={required}
                                            options={options}
                                            autoFocus={isFirstField}
                                            value={selectedOption || null}
                                            handleChange={handleSelectionChange}
                                            headerName={headerName}
                                        />
                                    </FormControl>
                                </div>
                            );
                        }

                        if (columnType === "text") {
                            return (
                                <div className={cssClassName}>
                                    <DTextField
                                        key={index}
                                        required={required}
                                        label={headerName}
                                        autoFocus={isFirstField}
                                        value={formData[columnKey] || ''}
                                        onChange={(e) => handleChange(e, columnKey, columnType)}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{
                                            sx: {
                                                fontSize: '14px',
                                                lineHeight: '22px',
                                            },
                                        }}
                                        disabled={(columnKey === 'party_name' && formData?.party_type_name?.length === 1 && formData?.party_type_name?.find(_ => _?.value === 3)) || columnKey === 'party_code'}
                                    />
                                </div>
                            );
                        }

                        if (columnType === "switch") {
                            return (
                                <div className={headerName === "Active" ? "d_col-2" : "d_col-4"}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                autoFocus={isFirstField}
                                                checked={formData[columnKey] || false}
                                                onChange={(e) => handleChange(e, columnKey, columnType)}
                                            // disabled={shouldDisableSwitch} // Disable based on the condition
                                            />
                                        }
                                        label={headerName}
                                    />
                                </div>
                            );
                        }

                        return React.cloneElement(component, { key: index });
                    })}
                    {formData?.party_type_name?.length > 1 && formData?.party_type_name?.some(item => item.value === 3) && columnResponse?.filter(_ => _?.accessorKey === "is_party_name_as_per_member_name").map((component, index) => {

                        const columnKey = component?.accessorKey;
                        const columnType = component?.column_type;
                        const headerName = component.header
                        return (
                            <div className={headerName === "Active" ? "d_col-2" : "d_col-4"}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formData[columnKey] || false}
                                            onChange={(e) => handleChange(e, columnKey, columnType)}
                                        />
                                    }
                                    label={headerName}
                                />
                            </div>
                        );
                    })}
                    {error && <em style={{ color: "red" }}>{error}</em>}
                </DialogContent>}
                DialogActions={
                    <>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button variant="contained" onClick={createFunction}>Save</Button>
                    </>
                }
            />
        </React.Fragment>
    );
});

export default PartyModal;
