import * as MuiIcons from "@mui/icons-material";
import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLOR } from "./common/constant";
import { usePostData } from "./common/function/api";
import { getLocalStorage } from "./common/function/common";
import Loader from "./components/loader";

const Dashboard = React.memo((props) => {
  const navigate = useNavigate();
  const userData = getLocalStorage("userAuth");
  const mutation = usePostData(`/dashboard_data`);
  const [dashboradData, setDashboradData] = useState([]);
  const preparedData = {
    user_name: userData?.email
  };
  const fetchDashboradData = async () => {
    try {
      const responseData = await mutation.mutateAsync(preparedData);

      if (responseData) {
        const preparedData = responseData?.card_data?.map((e, index) => {
          const icons = ["Business", "HomeWork", "Group", "Person", "Inventory", "ReceiptLong", "LocalOffer", "ThreeP"];
          const colors = ["#191919", "#1A73E8", "#43A047", "#D81B60"]; // Dynamic colors

          return {
            ...e,
            icon: icons[index % icons.length], // Rotate icons
            color: colors[index % colors.length] // Assign color from array or API
          };
        });

        setDashboradData(preparedData); // Update the dashboard data state
      }
    } catch (error) {
      console.error('Error:', error); // Log the error
    }
  };

  useEffect(() => {
    fetchDashboradData();
  }, []);

  const getIconComponent = (iconName, color) => {
    const IconComponent = MuiIcons[iconName]; // Fetch the icon dynamically
    return IconComponent ? <IconComponent sx={{ color: COLOR.white }} /> : <MuiIcons.Help sx={{ color: COLOR.white }} />;
  };

  return (
    <React.Fragment>
      {mutation?.isPending ? <Loader /> :
        <Box sx={{ padding: "25px 15px", overflowY: "auto" }}>
          <div className="d_flex-align" style={{ flexWrap: "wrap", gap: "15px" }}>
            {dashboradData?.length !== 0 && dashboradData?.map((item, index) => (
              <Card onClick={() => navigate(item?.route_path)} key={index} sx={{ marginBottom: "15px", overflow: "unset", position: "relative", cursor: "pointer", maxWidth: 345, width: "100%", border: `1px solid transparent`, ':hover': { border: `1px solid ${item.color}` }, boxShadow: "0rem 0.2rem 0.375rem 0.115rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)" }}>
                <CardContent style={{ pb: "15px !imporatant" }}>
                  <Box
                    sx={{
                      background: `linear-gradient(195deg, ${item.color}, ${item.color})`,
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "4rem",
                      height: "4rem",
                      position: "absolute",
                      top: "-15px",
                      boxShadow: "0rem 0.25rem 1.25rem 0rem rgba(0, 0, 0, 0.14),0rem 0.4375rem 0.625rem -0.3125rem rgba(64, 64, 64, 0.4)"
                    }}
                  >
                    {getIconComponent(item.icon)} {/** Dynamically render the icon */}
                  </Box>
                  <div className="d_flex-align d_content-end">
                    <Box sx={{ textAlign: "right" }}>
                      <Typography gutterBottom variant="h6" component="div" sx={{ margin: "0 0 10px 0", fontSize: "16px", lineHeight: "18px" }}>
                        {item?.card_name}
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div" sx={{ margin: 0, fontSize: "24px", lineHeight: "22px", fontWeight: "bold" }}>
                        {item?.data_count}
                      </Typography>
                    </Box>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </Box>}
    </React.Fragment>
  );
});

export default Dashboard;