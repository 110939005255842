import React from "react";
import { Box, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";

const DModal = React.memo((props) => {
    return (
        <React.Fragment>
            <Box sx={{ borderRadius: "5px" }}>
                <Dialog
                    open={props?.open}
                    onClose={props?.onClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="lg"
                    fullWidth
                    sx={{
                        '& .MuiInputBase-root': {
                            background: "white",
                            boxShadow: "1px 2px 6px #e9e9e9"
                        },
                        '& .MuiDialog-paper': {
                            width: props?.modalWidth ? props?.modalWidth : '1020px',
                            maxWidth: '100%',
                            borderRadius: 0,
                            background: props?.background ? props?.background : "#f9f9f9"
                        },
                        '& .MuiModal-backdrop': {
                            ...(!props?.backDrop && { backgroundColor: "rgba(0, 0, 0, 0)" }),
                            backdropFilter: "blur(1px)"
                        },
                    }}
                >
                    {props?.DialogTitle &&
                        <DialogTitle className={`dialog-header`} variant="h6" textAlign="left">
                            {props?.DialogTitle}
                            <div onClick={props?.onClose} className="close-box">
                                <Close sx={{ fontSize: 24 }} color="inherit" />
                            </div>
                        </DialogTitle>
                    }
                    {props?.DialogContent}
                    <Box sx={{ borderTop: "1px solid #ccc" }}>
                        <DialogActions className={props?.DialogActionsclassName}>
                            {props?.DialogActions}
                        </DialogActions>
                    </Box>
                </Dialog>
            </Box>
        </React.Fragment>
    );
});

export default DModal;
