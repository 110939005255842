import { Delete } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react"

const ConfirmationModal = (props) => {
    return (
        <Dialog
            open={props?.open}
            onClose={props?.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiDialog-paper': {
                    width: '500px',
                },
                '& .MuiModal-backdrop': {
                    backdropFilter: "blur(1px)"
                },
            }}
        >
            <DialogTitle id="alert-dialog-title" className="dialog-header">
                {props?.header}
            </DialogTitle>
            <DialogContent sx={{ padding: "10px 20px 20px !important" }}>
                <Box textAlign="center" sx={{
                    background: "#ff00000f",
                    width: "70px",
                    height: "70px",
                    margin: "10px auto",
                    borderRadius: "50%",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <Delete sx={{ height: "36px", width: "36px" }} color="error" />
                </Box>
                <DialogContentText id="alert-dialog-description" textAlign="center">
                    {props?.title}
                </DialogContentText>
            </DialogContent>
            <Box sx={{ borderTop: "1px solid #ccc" }}>
                <DialogActions>
                    <Button onClick={props?.onClose}>No</Button>
                    <Button autoFocus onClick={props?.action} variant="contained">
                        Yes
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
};

export default ConfirmationModal;
